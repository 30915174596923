/* eslint-disable no-shadow */
import { ErrorData } from '@b2chat/chat-center-sdk';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuickMessage } from '@types';

type Loading = 'fetch-quick-message-list' | 'remove-quick-message';

// eslint-disable-next-line @typescript-eslint/ban-types
type Filter = { botId: string; filters: object };

export type QuickMessagesState = {
  error?: ErrorData;
  loading: Loading[];
  filter?: Filter;
  items: QuickMessage[];
};

const initialState: QuickMessagesState = {
  loading: [],
  items: [],
};

const quickMessagesSlice = createSlice({
  initialState,
  name: 'quickMessages',
  reducers: {
    fetchQuickMessageList(state, action: PayloadAction<Filter>) {
      state.loading.push('fetch-quick-message-list');
      state.filter = action.payload;
      state.error = undefined;
      state.items = [];
    },
    fetchQuickMessageListSuccess(state, action: PayloadAction<QuickMessage[]>) {
      state.items = action.payload;
    },
    fetchQuickMessageListFailure(state, action: PayloadAction<ErrorData>) {
      state.error = action.payload;
    },
    fetchQuickMessageListFulfill(state) {
      state.loading = state.loading.filter(
        l => l !== 'fetch-quick-message-list'
      );
    },
    removeQuickMessageItem(state, action: PayloadAction<string>) {
      state.loading.push('remove-quick-message');
      const item = state.items.find(item => item.id === action.payload);
      if (item) item.hidden = true;
    },
    removeQuickMessageItemSuccess(state, action: PayloadAction<string>) {
      const index = state.items.findIndex(item => item.id === action.payload);
      if (index !== -1) state.items.splice(index, 1);
    },
    removeQuickMessageItemFailure(
      state,
      action: PayloadAction<{ id: string; error: ErrorData }>
    ) {
      // state.error = action.payload;
      const item = state.items.find(item => item.id === action.payload.id);
      if (item) item.hidden = false;
    },
    removeQuickMessageItemFulfill(state) {
      state.loading = state.loading.filter(l => l !== 'remove-quick-message');
    },
  },
});

export default quickMessagesSlice.reducer;

export const {
  fetchQuickMessageList,
  fetchQuickMessageListSuccess,
  fetchQuickMessageListFailure,
  fetchQuickMessageListFulfill,
  removeQuickMessageItem,
  removeQuickMessageItemSuccess,
  removeQuickMessageItemFailure,
  removeQuickMessageItemFulfill,
} = quickMessagesSlice.actions;
