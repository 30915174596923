import {
  ContactError,
  ContactSuccess,
  GenericTag,
  getTagData,
  NewTag,
  sortTags,
} from '@src/components/AdminHome/Tags/utils';
import { Tag } from '@src/services/tags.service';
import { Selector } from '@types';
import { activeChatSelector } from './chats';
import { departmentItemByIdSelector } from './departments';

export const getEditingTag: Selector<NewTag> = state =>
  state.adminHome.tags.tag;

export const getEditingTagFromOldComponent: Selector<Partial<Tag>> = state => {
  const { color, active, emoji, name } = state.adminHome.tags.tag;
  return {
    active,
    color,
    emoji,
    tagName: name,
    isNew: state.adminHome.tags.isNewTag,
  };
};

type ConverTagsListViewState = {
  botId?: number | string;
  chatId?: string;
  departmentId?: number;
};

export const getConverTagsListViewState: Selector<
  ConverTagsListViewState
> = state => {
  const chat = activeChatSelector(state);
  const chatId = chat?.id;
  const standardBotId = chat?.botId;
  const departmentId = chat?.businessProcessNode?.id;
  let botId = null;

  if (chat?.businessProcessNode?.id) {
    botId = departmentItemByIdSelector(state)(
      chat.businessProcessNode?.id
    )?.botId;
  }

  return {
    chatId,
    botId: botId ?? standardBotId,
    departmentId,
  };
};

export const getConverAssignedTags: Selector<GenericTag[]> = state =>
  state.adminHome.tags.converTags
    .filter(tag => tag.assigned)
    .map(t => getTagData(t));

export const getConverTags: Selector<GenericTag[]> = state =>
  state.adminHome.tags.converTags.map(t => getTagData(t));

export const getConverUpdateTagsError: Selector<boolean> = state => {
  const error = state.adminHome.tags.converTagsError;
  return !!error;
};

export const getForceToUpdateTagList: Selector<boolean> = state =>
  state.adminHome.tags.forceToUpdateTagsList;

export const getContactTags: Selector<NewTag[]> = state =>
  sortTags(state.adminHome.tags.contactTags);

export const getContactTagsWithAssignation: Selector<NewTag[]> = state => {
  const tags = state.adminHome.tags.contactTags;
  const assignedTags = getContactTagsById(state);
  return sortTags(
    tags
      .map(t => ({
        ...t,
        assigned: assignedTags.findIndex(tag => tag.name === t.name) !== -1,
      }))
      .filter(t => t.active === true)
  );
};

export const getIsEditingATag: Selector<boolean> = state =>
  !state.adminHome.tags.isNewTag;

export const getContactTagsById: Selector<NewTag[]> = state =>
  sortTags(state.adminHome.tags.contactTagsById);

export const getContactTagsError: Selector<ContactError | null> = state =>
  state.adminHome.tags.contactError;

export const getContactTagsSuccess: Selector<
  ContactSuccess | boolean | null
> = state => state.adminHome.tags.success;

export const getContactTagsByIdLoading: Selector<boolean> = state =>
  state.adminHome.tags.loading.includes('fetch-contact-tags-by-id');

export default getEditingTag;
