import { ErrorData } from '@b2chat/chat-center-sdk';
// eslint-disable-next-line import/no-cycle
import { ErrorPayload, QuotedMessage } from '@src/types';
import {
  ChatActionType,
  ChatActionsStatus,
  ChatSideType,
  ChatTray,
  ChatsOnHold,
  ChatsRequest,
} from '@src/types/chats';

export type ChatStateAction = {
  type: ChatActionType;
  status: ChatActionsStatus;
  details?: ChatActionErrorDetail;
};

export type ChatActionErrorDetail = {
  traceId?: string;
  code: string;
  message?: string;
  timestamp?: string;
};

export type TransformTextAction = {
  apiName?: string;
  command: string;
  language?: string;
};

export type ChatsState = {
  chatsSearch: string;
  activeAgentId?: number;
  activeChat?: ChatTray;
  activeChatSide: ChatSideType.ON_HOLD | ChatSideType.REQUEST;
  chatsOnHold: {
    loading: 'idle' | 'pending';
    offset: number;
    limit: number;
    error?: ErrorData | ErrorPayload;
  } & ChatsOnHold;
  chatsRequest: {
    loading: 'idle' | 'pending';
    offset: number;
    limit: number;
    error?: ErrorData | ErrorPayload;
  } & ChatsRequest;
  quotedMessages: Record<string, QuotedMessage>;
  writtenMessages: Record<string, string>;
  actionChat?: ChatStateAction;
  transformTextAI: {
    loading: 'idle' | 'pending' | 'success' | 'failure' | 'fulfill';
    closeErrorMsg: boolean;
    error?: ErrorData | ErrorPayload;
    outputText?: string;
    requestParams?: {
      apiName: string;
      command: string;
      language: string;
    };
    disabledStop: boolean;
  };
  isActiveWindow: boolean;
  crmButton: {
    loading: 'idle' | 'pending' | 'success' | 'failure' | 'fulfill';
    sendInfo: 'idle' | 'pending' | 'success' | 'failure' | 'fulfill';
    show: boolean;
  };
};

const initialStateChats: ChatsState = {
  chatsSearch: '',
  activeChatSide: ChatSideType.ON_HOLD,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  chatsOnHold: {
    tray: [],
    offset: 0,
    limit: 20,
    totalChatsOnHold: 0,
    loading: 'idle',
    error: undefined,
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  chatsRequest: {
    tray: [],
    offset: 0,
    limit: 20,
    totalChatRequests: 0,
    loading: 'idle',
    error: undefined,
  },

  quotedMessages: {},
  writtenMessages: {},
  transformTextAI: {
    loading: 'idle',
    closeErrorMsg: true,
    disabledStop: false,
  },
  isActiveWindow: false,
  crmButton: {
    loading: 'idle',
    show: false,
    sendInfo: 'idle',
  },
};

export default initialStateChats;
