import B2ChatClient from '@client-sdk';
import { contactAssignmentActions } from '@src/reducers/contactAssignment';
import {
  fetchContactById,
  fetchFechContactByIdFailure,
  fetchFechContactByIdFulfill,
  fetchFechContactByIdSuccess,
} from '@src/reducers/contactRToolkit';
import { ContactAssignment } from '@src/types/contactAssignment';
import { ErrorData } from '@types';
import { B2ChatAPI } from '@types-api';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

function* fetchContactByIdSaga(action: ReturnType<typeof fetchContactById>) {
  const { id } = action.payload;
  try {
    const response = (yield call(
      [B2ChatClient.resources.contacts.actions, 'getContactInfo'],
      { params: { id } }
    )) as B2ChatAPI.Response<B2ChatAPI.Contact.FetchContactById>;
    if (response.error) {
      yield put(fetchFechContactByIdFailure(response.error));
    } else {
      yield put(fetchFechContactByIdSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchFechContactByIdFailure(error as ErrorData));
  } finally {
    yield put(fetchFechContactByIdFulfill());
  }
}

function* getAssignedToContactSaga(
  action: ReturnType<typeof contactAssignmentActions.getAssignedToContact>
) {
  const contactId = action.payload;
  try {
    const response = (yield call(
      [B2ChatClient.resources.assignedContact.actions, 'getAssignedContact'],
      { params: { contactId } }
    )) as B2ChatAPI.Response<ContactAssignment>;
    if (response.error) throw new Error();

    yield put(
      contactAssignmentActions.getAssignedToContactSuccess({
        contactId,
        assignedTo: response.data,
      })
    );
  } catch (error) {
    yield put(contactAssignmentActions.getAssignedToContactError(contactId));
  }
}

function* assignContactSaga(
  action: ReturnType<typeof contactAssignmentActions.assignContact>
) {
  const { contactId, assignedTo, replace = false } = action.payload;
  try {
    const assignedContactAction = replace
      ? 'patchAssignContact'
      : 'assignContact';
    const response = (yield call(
      [B2ChatClient.resources.assignedContact.actions, assignedContactAction],
      { params: { contactId }, data: assignedTo }
    )) as B2ChatAPI.Response<B2ChatAPI.Contact.FetchContactById>;
    if (response.error) throw new Error();

    yield put(
      contactAssignmentActions.assignContactSuccess({ contactId, assignedTo })
    );
  } catch (error) {
    yield put(contactAssignmentActions.assignContactError(contactId));
  } finally {
    yield delay(3000);
    yield put(contactAssignmentActions.assignContactFulfill(contactId));
  }
}

function* unassignmentToContactSaga(
  action: ReturnType<typeof contactAssignmentActions.unassignToContact>
) {
  const contactId = action.payload;
  try {
    const response = (yield call(
      [B2ChatClient.resources.assignedContact.actions, 'unassignContact'],
      { params: { contactId } }
    )) as B2ChatAPI.Response<ContactAssignment>;
    if (response.error) throw new Error();

    yield put(contactAssignmentActions.unassignToContactSuccess(contactId));
  } catch (error) {
    yield put(contactAssignmentActions.unassignToContactError(contactId));
  } finally {
    yield delay(3000);
    yield put(contactAssignmentActions.unassignToContactFulfill(contactId));
  }
}

function* ContactSaga() {
  yield takeLatest(fetchContactById, fetchContactByIdSaga);
  yield takeLatest(
    contactAssignmentActions.getAssignedToContact,
    getAssignedToContactSaga
  );
  yield takeLatest(contactAssignmentActions.assignContact, assignContactSaga);
  yield takeLatest(
    contactAssignmentActions.unassignToContact,
    unassignmentToContactSaga
  );
}

export default ContactSaga;
