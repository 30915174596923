import { ErrorData } from '@b2chat/chat-center-sdk';
import B2ChatClient from '@client-sdk';
import {
  fetchQuickMessageList,
  fetchQuickMessageListFailure,
  fetchQuickMessageListFulfill,
  fetchQuickMessageListSuccess,
  removeQuickMessageItem,
  removeQuickMessageItemFailure,
  removeQuickMessageItemFulfill,
  removeQuickMessageItemSuccess,
} from '@reducers/quickMessages';
import { QuickMessage } from '@types';
import { B2ChatAPI } from '@types-api';
import { call, put, takeLeading } from 'redux-saga/effects';

export default function* quickMessageSaga() {
  // eslint-disable-next-line func-names
  yield takeLeading(fetchQuickMessageList, function* (action) {
    const { botId, filters } = action.payload;
    try {
      const response: B2ChatAPI.Response<QuickMessage[]> = botId
        ? yield call(
            B2ChatClient.resources.quickMessage.actions
              .retrieveQuickMessageList,
            { data: filters, params: { botId } }
          )
        : yield call(
            B2ChatClient.resources.quickMessage.actions
              .retrieveQuickMessageListAny,
            { data: filters }
          );

      if (response.data) {
        yield put(fetchQuickMessageListSuccess(response.data));
      } else {
        throw response.error;
      }
    } catch (e) {
      yield put(fetchQuickMessageListFailure(e as ErrorData));
    } finally {
      yield put(fetchQuickMessageListFulfill());
    }
  });

  // eslint-disable-next-line func-names
  yield takeLeading(removeQuickMessageItem, function* (action) {
    const id = action.payload;
    try {
      const response: B2ChatAPI.Response<QuickMessage[]> = yield call(
        B2ChatClient.resources.quickMessage.actions.removeQuickMessageItem,
        { params: { id } }
      );

      if (!response.error) {
        yield put(removeQuickMessageItemSuccess(id));
      } else {
        throw response.error;
      }
    } catch (e) {
      yield put(removeQuickMessageItemFailure({ error: e as ErrorData, id }));
    } finally {
      yield put(removeQuickMessageItemFulfill());
    }
  });
}
