/* eslint-disable no-console */
import BackendProxy from '../logic/backendproxy';
import ContactBackendProxy from '../logic/contactBackendProxy';

export const OK_LOADED_CONTACTS_LIST = 'CONTACT.OK_LOADED_CONTACTS_LIST';
export const BOTS_DATA_LIST = 'CONTACT.BOTS_DATA_LIST';
export const CITY_DATA_LIST = 'CONTACT.CITY_DATA_LIST';
export const AVAILABLE_MESSAGIN_ACCOUNTS =
  'CONTACT.AVAILABLE_MESSAGIN_ACCOUNTS';
export const UPDATE_FILTER_BYCITY = 'CONTACT.UPDATE_FILTER_BYCITY';
export const UPDATE_FILTER_BY_DATE = 'CONTACT.UPDATE_FILTER_BY_DATE';
export const UPDATE_FILTER_GENERAL = 'CONTACT.UPDATE_FILTER_GENERAL';

export const UPDATE_CONTACT = 'CONTACT.UPDATE_CONTACT';
export const STATUS_UPDATE_CONTACT = 'CONTACT.STATUS_UPDATE_CONTACT';
export const SHOW_NOTIFICATIONS = 'CONTACT.SHOW_NOTIFICATIONS';
export const DATA_NEW_CONTACT = 'CONTACT.DATA_NEW_CONTACT';
export const CLEAR_CONTACT_DATA = 'CONTACT.CLEAR_CONTACT_DATA';
export const SEARCH_CONTACT_LOCAL_DATA = 'CONTACT.SEARCH_CONTACT_LOCAL_DATA';
export const TIMEFRAME_SELECTED = 'CONTACT.TIMEFRAME_SELECTED';
export const CONTACT_ALL = 'CONTACT_ALL';
export const CUSTOM_TIMEFRAME = 'CUSTOM_TIMEFRAME';
export const TIMEFRAME_START_DATE_SELECTED = 'TIMEFRAME_START_DATE_SELECTED';
export const TIMEFRAME_END_DATE_SELECTED = 'TIMEFRAME_END_DATE_SELECTED';

export function updateFilterValue(type, value) {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    switch (type) {
      case 'byCity':
        dispatch(updateFilter(UPDATE_FILTER_BYCITY, value));
        break;
      case 'general':
        dispatch(updateFilter(UPDATE_FILTER_GENERAL, value));
        break;
      case 'dateRange':
        dispatch(updateFilter(UPDATE_FILTER_BY_DATE, value));
        break;
      default:
        break;
    }

    if (getState().adminHome.contact.contacts.filters.custom_timeframe) {
      if (
        getState().adminHome.contact.contacts.filters.timeframe_start_date ===
          null ||
        getState().adminHome.contact.contacts.filters.timeframe_end_date ===
          null
      ) {
        return false;
      }
    }

    if (type === 'general' && value !== '') {
      dispatch(loadContactsWithoutFilters());
    } else {
      dispatch(loadContactsByFilters());
    }
  };
}

function updateFilter(type, value) {
  return {
    type,
    value,
  };
}

export function loadContactsByFilters() {
  return (dispatch, getState) => {
    const merchantId = getState().loginAuthentication.success.employer.id;

    ContactBackendProxy.getContactsByFilters(
      merchantId,
      getState().adminHome.contact.contacts.filters,
      respData => {
        const { contacts } = respData;

        setTimeout(() => {
          dispatch(okLoadedContactsList(contacts));
        }, 1000);
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(
          `Error Getting contacts! Status code: ${respStatus}`,
          error
        );
        // dispatch(errorLoadedContactsList(error));
      }
    );
  };
}

export function loadContactsWithoutFilters() {
  return (dispatch, getState) => {
    const merchantId = getState().loginAuthentication.success.employer.id;

    ContactBackendProxy.getContactsWithoutFilters(
      merchantId,
      getState().adminHome.contact.contacts.filters,
      respData => {
        const { contacts } = respData;

        setTimeout(() => {
          dispatch(okLoadedContactsList(contacts));
        }, 1000);
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(
          `Error Getting contacts! Status code: ${respStatus}`,
          error
        );
        // dispatch(errorLoadedContactsList(error));
      }
    );
  };
}

export function getBotsData() {
  return dispatch => {
    BackendProxy.getCurrentUserBots(
      true,
      respData => {
        dispatch(loadBotsData(respData));
      },
      (respStatus, error) => {
        // Handle errors that might occur while uploading media
        console.log(
          `Error Getting contacts! Status code: ${respStatus}`,
          error
        );
        dispatch(loadBotsData(null));
      }
    ).then(r => {
      console.debug('Loaded contact-associable bots', r);
    });
  };
}

export function getAvailableMessagingAccounts(contact) {
  return (dispatch, getState) => {
    const merchantId = getState().loginAuthentication.success.employer.id;

    dispatch(loadAvailableMessagingAccounts(null, contact));

    ContactBackendProxy.getAvailableMessagingAccounts(
      merchantId,
      contact,
      respData => {
        dispatch(loadAvailableMessagingAccounts(respData, contact));
      },
      (respStatus, error) => {
        console.log(
          `Error Getting contacts! Status code: ${respStatus}`,
          error
        );
      }
    );
  };
}

function loadAvailableMessagingAccounts(messaginAccount, contact) {
  return {
    type: AVAILABLE_MESSAGIN_ACCOUNTS,
    messaginAccount,
    contact,
  };
}

export function getCacheDataContactByIdentification(contact) {
  return {
    type: SEARCH_CONTACT_LOCAL_DATA,
    contact,
  };
}

function loadBotsData(bots) {
  return {
    type: BOTS_DATA_LIST,
    bots,
  };
}

function okLoadedContactsList(contacts) {
  return {
    type: OK_LOADED_CONTACTS_LIST,
    contacts,
  };
}

export function exportFilteredContacts(
  emailMsg,
  searchFilter,
  createdAtFrom,
  createdAtTo,
  onlyBlacklisted,
  searchByLastUpdate,
  company,
  tagFilter,
  assignTo
) {
  return () => {
    ContactBackendProxy.exportFilteredContacts(
      emailMsg,
      searchFilter,
      createdAtFrom,
      createdAtTo,
      onlyBlacklisted,
      searchByLastUpdate,
      company,
      tagFilter,
      assignTo
    )
      .then(respData => {
        console.log(
          `Contacts successfully exported ${JSON.stringify(respData)}`
        );
      })
      .catch(reason => {
        console.log(
          `Error exporting contacts! Reason: ${JSON.stringify(reason)}`
        );
      });
  };
}

export function showNotifications(type, message) {
  return dispatch => {
    dispatch(notifications(type, message));
  };
}

function notifications(type, message) {
  return {
    type,
    message,
  };
}

export function clearContactData() {
  return {
    type: CLEAR_CONTACT_DATA,
  };
}

export function setDataNewContact(field, value) {
  return {
    type: DATA_NEW_CONTACT,
    field,
    value,
  };
}

export function searchCityByName(city, lstCities) {
  let cont = 0;
  let response = null;
  while (cont < lstCities.length) {
    if (city === lstCities[cont].name) {
      response = lstCities[cont];
    }
    cont += 1;
  }

  return response;
}

export function onContactTimeframeSelected(timeframe) {
  return {
    type: TIMEFRAME_SELECTED,
    timeframe,
  };
}

export function onContactTimeframeStartDateSelected(date) {
  return {
    type: TIMEFRAME_START_DATE_SELECTED,
    date,
  };
}

export function onContactTimeframeEndDateSelected(date) {
  return {
    type: TIMEFRAME_END_DATE_SELECTED,
    date,
  };
}
