import { ChatActionType, ChatSideType, ChatTray } from '@src/types/chats';
// eslint-disable-next-line import/no-cycle
import { RootState } from '@types';

export const isChatsOnHoldLoadingSelector = (state: RootState) =>
  state.chats.chatsOnHold?.loading === 'pending';

export const chatsOnHoldSelector = (state: RootState) =>
  state.chats.chatsOnHold;

export const chatsOnHoldItemsSelector = (state: RootState) =>
  state.chats.chatsOnHold?.tray ?? [];

export const chatsOnHoldIdsSelector = (state: RootState) =>
  state.chats.chatsOnHold?.tray?.map(chat => chat.id) ?? [];

export const totalChatsOnHoldSelector = (state: RootState) =>
  state.chats.chatsOnHold.totalChatsOnHold;

export const isFirstTrayChatsOnHoldSelector = (state: RootState) =>
  state.chats?.chatsOnHold?.offset === 0;

export const totalFilteredChatsOnHoldSelector = (state: RootState) =>
  state.chats.chatsOnHold.totalFilteredChatsOnHold;

export const isChatsRequestLoadingSelector = (state: RootState) =>
  state.chats.chatsRequest?.loading === 'pending';

export const chatsRequestSelector = (state: RootState) =>
  state.chats.chatsRequest;

export const chatsRequestItemsSelector = (state: RootState) =>
  state.chats.chatsRequest?.tray ?? [];

export const chatsRequestIdsSelector = (state: RootState) =>
  state.chats.chatsRequest?.tray?.map(chat => chat.id) ?? [];

export const totalChatsRequestSelector = (state: RootState) =>
  state.chats.chatsRequest.totalChatRequests;

export const isFirstTrayChatsRequestSelector = (state: RootState) =>
  state.chats?.chatsRequest?.offset === 0;

export const transformTextAISelector = (state: RootState) =>
  state.chats.transformTextAI;

export const totalFilteredChatsRequestSelector = (state: RootState) =>
  state.chats.chatsRequest.totalFilteredChatRequests;

export const allChatsSelector = (state: RootState) => {
  const chatsOnHold = chatsOnHoldItemsSelector(state);
  const chatsRequest = chatsRequestItemsSelector(state);
  return [
    ...(chatsOnHold?.length ? chatsOnHold : []),
    ...(chatsRequest?.length ? chatsRequest : []),
  ];
};

export const activeChatSelector = (state: RootState) =>
  (state.chats.activeChat || {}) as ChatTray;

export const activeChatSideSelector = (state: RootState) =>
  state.chats.activeChatSide;

export const chatByIdSelector = (chatId: string) => (state: RootState) => {
  const chats = allChatsSelector(state);
  return chats?.find(({ id }) => id === chatId) ?? {};
};

export const sideByChatIdSelector = (chatId: string) => (state: RootState) => {
  const inChatsOnHold = state.chats.chatsOnHold.tray.some(
    chat => chat.id === chatId
  );
  if (!inChatsOnHold) {
    const inChatsRequest = state.chats.chatsRequest.tray.some(
      chat => chat.id === chatId
    );
    if (!inChatsRequest) return null;
    return ChatSideType.REQUEST;
  }
  return ChatSideType.ON_HOLD;
};

export const activeChatIdSelector = (state: RootState) =>
  state.chats.activeChat?.id as string;

export const chatsSearchValueSelector = (state: RootState) =>
  state.chats.chatsSearch || '';

export const isSearchingChatsSelector = (state: RootState) => {
  const { activeChatSide } = state.chats;
  const { filters } = state.chatsSearch;
  return activeChatSide === ChatSideType.ON_HOLD
    ? !!filters.ON_HOLD?.contactName?.length
    : !!filters.REQUEST?.contactName?.length;
};

export const activeQuotedMessageSelector = (state: RootState) => {
  const activeChatId = activeChatIdSelector(state);
  return quotedMessageByChatIdSelector(activeChatId)(state);
};

export const quotedMessageByChatIdSelector =
  (chatId: string) => (state: RootState) => {
    if (state.chats.quotedMessages[chatId])
      return state.chats.quotedMessages[chatId];
    return null;
  };

export const activeWrittenMessageSelector = (state: RootState) => {
  const activeChatId = activeChatIdSelector(state);
  return writtenMessageByChatIdSelector(activeChatId)(state);
};

export const writtenMessageByChatIdSelector =
  (chatId: string) => (state: RootState) => {
    if (state.chats.writtenMessages[chatId])
      return state.chats.writtenMessages[chatId];
    return '';
  };

export const crmButtonSelector = (state: RootState) => state.chats.crmButton;

/* -------------------------------------------------------------------------- */
/*                                Actions Chats                               */
/* -------------------------------------------------------------------------- */

export const chatActivationActionSelector = (state: RootState) => {
  const action = state.chats.actionChat;
  if (action?.type === ChatActionType.ACTIVATION_CHAT) return action;
  return null;
};

export const chatCloseActionSelector = (state: RootState) => {
  const action = state.chats.actionChat;
  if (action?.type === ChatActionType.CLOSE_CHAT) return action;
  return null;
};

export const chatPickupActionSelector = (state: RootState) => {
  const action = state.chats.actionChat;
  if (action?.type === ChatActionType.PICKUP_CHAT) return action;
  return null;
};

export const chatExportCrmActionSelector = (state: RootState) => {
  const action = state.chats.actionChat;
  if (action?.type === ChatActionType.EXPORT_CRM_CHAT) return action;
  return null;
};

export const isActiveConsoleWindow = (state: RootState) =>
  state.chats.isActiveWindow;
