import B2ChatClient, { B2ChatClientBroadcast } from '@client-sdk';
// eslint-disable-next-line import/no-extraneous-dependencies
import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import {
  TCampaign,
  TactionPayload,
  TapiChartReport,
  TapiStatistics,
  Tchart,
  TdeliveryMassive,
  TdeliveryMassiveState,
  TfailedApiChart,
  applySearchFilter,
  fetchCancelDeliveryRequest,
  fetchCancelDeliverySuccess,
  fetchDeliveriesMassiveFailure,
  fetchDeliveriesMassiveFulfill,
  fetchDeliveriesMassiveSuccess,
  fetchDownloadReport,
  fetchDownloadReportFailure,
  fetchDownloadReportSuccess,
  fetchGetApiChart,
  fetchGetApiChartFailure,
  fetchGetApiChartSuccess,
  fetchGetApiStatistics,
  fetchGetApiStatisticsFailure,
  fetchGetApiStatisticsSuccess,
  fetchGetByIdFailure,
  fetchGetByIdFulfill,
  fetchGetByIdRequest,
  fetchGetByIdSuccess,
  fetchGetCampaigns,
  fetchGetCampaignsFailure,
  fetchGetCampaignsSuccess,
  fetchGetChartByRangeCode,
  fetchGetChartByRangeCodeFailure,
  fetchGetChartByRangeCodeSuccess,
  fetchGetFailedApiChart,
  fetchGetFailedApiChartFailure,
  fetchGetFailedApiChartSuccess,
  fetchGetTemplates,
  fetchGetTemplatesFailure,
  fetchGetTemplatesSuccess,
  setChangeInfo,
  setResponseCode,
} from '@src/reducers/deliveriesMassive';
import selectDeliveriesMassive from '@src/selectors/deliveriesMassive';
import { B2ChatAPI } from '@types-api';
import configChartjs2 from '@utils/chartjs2';
import configChartjs2ApiReport from '@utils/chartjs2ApiReport';
import { WhatsAppTemplate } from '../types';

export function* fetchDeliveriesMassiveSaga() {
  try {
    const { messages }: TdeliveryMassiveState = yield select(
      selectDeliveriesMassive
    );

    const response: B2ChatAPI.Response<TactionPayload> = yield call(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      B2ChatClient.resources.deliveriesMassive.actions.getSomeByPagination,
      {
        params: { ...messages.filter },
      }
    );

    if (response.data) yield put(fetchDeliveriesMassiveSuccess(response.data));
  } catch (error: unknown) {
    yield put(
      fetchDeliveriesMassiveFailure({
        details: (error as Error).message,
        errorCode: 'OPERATION_FAILED',
        timestamp: Date.now(),
        traceId: 'Get deliveries massive',
      })
    );
  } finally {
    yield put(fetchDeliveriesMassiveFulfill());
  }
}

export function* fetchGetCharSaga(
  action: ReturnType<typeof fetchGetChartByRangeCode>
) {
  try {
    const { broadcastId, rangeTime } = action.payload;

    const response: B2ChatAPI.Response<Tchart> = yield call(
      B2ChatClient.resources.deliveriesMassive.actions.getChartByRangeCode,
      {
        params: {
          rangeCode: rangeTime,
          broadcastId,
        },
      }
    );

    if (response.data) {
      const config = configChartjs2(response.data, rangeTime);
      yield put(fetchGetChartByRangeCodeSuccess(config));
    }
  } catch (error: unknown) {
    yield put(
      fetchGetChartByRangeCodeFailure({
        details: (error as Error).message,
        errorCode: 'OPERATION_FAILED',
        timestamp: Date.now(),
        traceId: 'Get chart by range code for delivering massive',
      })
    );
  } finally {
    yield put(fetchDeliveriesMassiveFulfill());
  }
}

export function* fetchGetByIdSaga(
  action: ReturnType<typeof fetchGetByIdRequest>
) {
  try {
    const id = action.payload;
    const response: B2ChatAPI.Response<TdeliveryMassive> = yield call(
      B2ChatClient.resources.deliveriesMassive.actions.getByBroadcastId,
      {
        params: {
          broadcastId: id,
        },
      }
    );

    if (response.data) yield put(fetchGetByIdSuccess(response.data));
  } catch (error: unknown) {
    yield put(
      fetchGetByIdFailure({
        details: (error as Error).message,
        errorCode: 'OPERATION_FAILED',
        timestamp: Date.now(),
        traceId: 'Get delivery massive by id',
      })
    );
  } finally {
    yield put(fetchGetByIdFulfill());
    window.localStorage.removeItem('broadcastId');
  }
}

export function* fetchCancelDeliveryMassiveSaga() {
  try {
    const { messages, rowSelected }: TdeliveryMassiveState = yield select(
      selectDeliveriesMassive
    );
    const response: B2ChatAPI.Response<unknown> = yield call(
      B2ChatClient.resources.deliveriesMassive.actions.cancelDelivery,
      {
        params: {
          broadcastId: `${rowSelected?.id}`,
          username: `${rowSelected?.username}`,
        },
      }
    );

    if (response.data) {
      yield put(fetchCancelDeliverySuccess('200'));
      yield put(applySearchFilter(messages.filter));
    } else
      yield put(setResponseCode(response.error?.status as unknown as string));
  } catch (error: unknown) {
    yield put(
      fetchGetByIdFailure({
        details: (error as Error).message,
        errorCode: 'OPERATION_FAILED',
        timestamp: Date.now(),
        traceId: 'Cancel delivery massive',
      })
    );
  } finally {
    yield put(setChangeInfo());
  }
}

export function* fetchDownloadReportSaga(
  action: ReturnType<typeof fetchDownloadReport>
) {
  try {
    const { broadcastId, ...createReportParams } = action.payload;

    const response: B2ChatAPI.Response<{
      code: number;
      error: unknown;
    }> = yield call(
      B2ChatClientBroadcast.resources.broadCast.actions.downloadReport,
      {
        params: { broadcastId: broadcastId || '' },
        data: { ...createReportParams },
      }
    );

    const { data, error } = response;
    if (data?.code === 200) {
      yield put(fetchDownloadReportSuccess());
    } else {
      throw error;
    }
  } catch (error: unknown) {
    yield put(
      fetchDownloadReportFailure({
        details: (error as Error)?.message,
        errorCode: 'OPERATION_FAILED',
        timestamp: Date.now(),
        traceId: 'Download report for delivering massive',
      })
    );
  }
}

export function* fetchGetCampaignsSaga(
  action: ReturnType<typeof fetchGetCampaigns>
) {
  try {
    const response: B2ChatAPI.Response<TCampaign[]> = yield call(
      B2ChatClient.resources.deliveriesMassive.actions.getCampaigns,
      {
        params: action.payload,
      }
    );

    if (response.data) {
      yield put(fetchGetCampaignsSuccess(response.data));
    }
  } catch (error: unknown) {
    yield put(
      fetchGetCampaignsFailure({
        details: (error as Error).message,
        errorCode: 'OPERATION_FAILED',
        timestamp: Date.now(),
        traceId: 'Get campaigns for api report',
      })
    );
  }
}

export function* fetchGetTemplatesSaga() {
  try {
    const response: B2ChatAPI.Response<WhatsAppTemplate[]> = yield call(
      B2ChatClient.resources.templates.actions.getAllByMerchant
    );

    if (response.data) {
      yield put(fetchGetTemplatesSuccess(response.data));
    }
  } catch (error: unknown) {
    yield put(
      fetchGetTemplatesFailure({
        details: (error as Error).message,
        errorCode: 'OPERATION_FAILED',
        timestamp: Date.now(),
        traceId: 'Get templates for api report',
      })
    );
  }
}

export function* fetchGetApiStatisticsSaga(
  action: ReturnType<typeof fetchGetApiStatistics>
) {
  try {
    const response: B2ChatAPI.Response<TapiStatistics> = yield call(
      B2ChatClient.resources.deliveriesMassive.actions.getApiChartStatistics,
      {
        params: action.payload,
      }
    );

    if (response.data) {
      yield put(fetchGetApiStatisticsSuccess(response.data));
    }
  } catch (error: unknown) {
    yield put(
      fetchGetApiStatisticsFailure({
        details: (error as Error).message,
        errorCode: 'OPERATION_FAILED',
        timestamp: Date.now(),
        traceId: 'Get api statistics for api report',
      })
    );
  }
}

export function* fetchGetApiChartSaga(
  action: ReturnType<typeof fetchGetApiChart>
) {
  try {
    const response: B2ChatAPI.Response<TapiChartReport> = yield call(
      B2ChatClient.resources.deliveriesMassive.actions.getApiChart,
      {
        params: action.payload,
      }
    );

    if (response.data) {
      const configApiReport = configChartjs2ApiReport(
        response.data,
        null,
        action.payload.rangeCode
      );
      yield put(fetchGetApiChartSuccess(configApiReport));
    }
  } catch (error: unknown) {
    yield put(
      fetchGetApiChartFailure({
        details: (error as Error).message,
        errorCode: 'OPERATION_FAILED',
        timestamp: Date.now(),
        traceId: 'Get chart for Api report',
      })
    );
  }
}

export function* fetchGetFailedApiChartSaga(
  action: ReturnType<typeof fetchGetFailedApiChart>
) {
  try {
    const response: B2ChatAPI.Response<TfailedApiChart> = yield call(
      B2ChatClient.resources.deliveriesMassive.actions.getFailedApiChart,
      {
        params: action.payload,
      }
    );

    if (response.data) {
      const configApiReport = configChartjs2ApiReport(
        null,
        response.data,
        action.payload.rangeCode
      );
      yield put(fetchGetFailedApiChartSuccess(configApiReport));
    }
  } catch (error: unknown) {
    yield put(
      fetchGetFailedApiChartFailure({
        details: (error as Error).message,
        errorCode: 'OPERATION_FAILED',
        timestamp: Date.now(),
        traceId: 'Get failed chart for Api report',
      })
    );
  }
}

export default function* deliveriesMassiveSaga() {
  yield takeLatest(applySearchFilter, fetchDeliveriesMassiveSaga);
  yield takeLatest(fetchGetByIdRequest, fetchGetByIdSaga);
  yield takeLatest(fetchCancelDeliveryRequest, fetchCancelDeliveryMassiveSaga);
  yield takeLatest(fetchGetChartByRangeCode, fetchGetCharSaga);
  yield takeLatest(fetchDownloadReport, fetchDownloadReportSaga);
  yield takeLatest(fetchGetApiChart, fetchGetApiChartSaga);
  yield takeLatest(fetchGetCampaigns, fetchGetCampaignsSaga);
  yield takeLatest(fetchGetTemplates, fetchGetTemplatesSaga);
  yield takeLatest(fetchGetApiStatistics, fetchGetApiStatisticsSaga);
  yield takeLatest(fetchGetFailedApiChart, fetchGetFailedApiChartSaga);
}
