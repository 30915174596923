import {
  APIClient,
  APIClientSpec,
  ClientResponse,
  Options as ClientResourceOptions,
} from '@b2chat/chat-center-sdk';
import { miniSerializeError, SerializedError } from '@reduxjs/toolkit';
import type { BaseQueryApi } from '@reduxjs/toolkit/query';
import env from '@utils/env';
import _ from 'lodash';

export { APIClient, APIClientSpec };

const spec = _.merge({}, APIClientSpec, {
  baseURL: env.WEB_SERVICE_BASE_URI,
  logRequests: env.APP_ENV !== 'production',
});

const B2ChatClient = new APIClient<typeof spec>(spec);

const spec2 = _.merge({}, APIClientSpec, {
  baseURL: env.WEB_SERVICE_BROADCAST_BASE_URI,
  logRequests: env.APP_ENV !== 'production',
});

export const B2ChatClientBroadcast = new APIClient<typeof spec2>(spec2);

const spec3 = _.merge({}, APIClientSpec, {
  baseURL: env.WEB_SERVICE_ECOMMERCE_URI,
  logRequests: env.APP_ENV !== 'production',
});

export const B2ChatClientEcommerce = new APIClient<typeof spec3>(spec3);

export default B2ChatClient;

export type ClientResource = (
  options?: ClientResourceOptions
) => ClientResponse;

export type BaseQueryArgs = {
  resource: ClientResource;
  options: ClientResourceOptions;
};

type QueryErrorResult = {
  status: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string | number, any>;
};

const QueryErrorResultSym = Symbol.for('QueryErrorResult');

export async function b2chatBaseQuery(
  args: BaseQueryArgs,
  api: BaseQueryApi
): Promise<
  | { data: unknown; error?: undefined }
  | { data?: undefined; error: SerializedError | QueryErrorResult }
> {
  try {
    args.options.signal = api.signal;
    const result = await args.resource(args.options);
    if (result.error) {
      return {
        error: {
          brand: QueryErrorResultSym,
          status: result.error.status,
          data: result.error,
        } as QueryErrorResult,
      };
    }
    return { data: result.data };
  } catch (error) {
    return { error: miniSerializeError(error) };
  }
}

export function apiResource(
  resource: ClientResource,
  options: ClientResourceOptions
) {
  return { resource, options };
}

export function isQueryErrorResult(error: unknown): error is QueryErrorResult {
  return (error as { brand: symbol })?.brand === QueryErrorResultSym;
}
