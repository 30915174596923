import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ContactError,
  ContactSuccess,
  GenericTag,
  getTagData,
  NewTag,
} from '@src/components/AdminHome/Tags/utils';
import type { ErrorData } from '@types';

type Loading =
  | 'fetch-contact-tags'
  | 'create-contact-tag'
  | 'update-contact-tag'
  | 'delete-contact-tag'
  | 'assign-contact-tags'
  | 'unassign-contact-tags'
  | 'fetch-contact-tags-by-id';

type TagsType = 'CONTACT';

const getInitialState = () => ({
  active: true,
  color: '#FFFFFF',
  emoji: '😀',
  name: '',
});

export type TagsState = {
  error: ErrorData | null;
  converTagsError: ErrorData | null;
  loading: Loading[];
  tag: NewTag;
  isNewTag: boolean;
  converTags: GenericTag[];
  contactTags: NewTag[];
  forceToUpdateTagsList: boolean;
  contactTagsById: NewTag[];
  success: ContactSuccess | boolean | null;
  contactError: ContactError | null;
};

type TagsReducer<P = void> = (
  state: TagsState,
  action: PayloadAction<P>
) => void;

const initialState: TagsState = {
  error: null,
  converTagsError: null,
  loading: [],
  tag: getInitialState(),
  isNewTag: false,
  converTags: [],
  contactTags: [],
  forceToUpdateTagsList: false,
  contactTagsById: [],
  success: null,
  contactError: null,
};

const handleFetchConverTags: TagsReducer<GenericTag[]> = (state, action) => {
  if (!Array.isArray(action.payload)) {
    // eslint-disable-next-line no-console
    console.error(
      "error: received data isn't an array -> handleFetchConverTags",
      action.payload
    );
  }
  const converTags = Array.isArray(action.payload) ? action.payload : [];
  state.converTags = converTags;
};

const handleRequestConverTagsError: TagsReducer<ErrorData> = (
  state,
  action
) => {
  state.converTagsError = action.payload;
};

const handleTagOnChangeAttr: TagsReducer<NewTag> = (state, action) => {
  const tag = getTagData(action.payload);
  state.tag = tag;
};

const handleSetIsNewTag: TagsReducer = state => {
  state.isNewTag = true;
  state.tag = getInitialState();
};

const handleResetTagForm: TagsReducer = state => {
  state.tag = getInitialState();
  state.isNewTag = false;
  state.forceToUpdateTagsList = false;
};

const handleResetTagError: TagsReducer = state => {
  state.error = null;
  state.contactError = null;
};

const handleResetTagSuccess: TagsReducer = state => {
  state.success = null;
};

type IHandleToggleTagAssing = {
  tagName: string;
  checked: boolean;
};

const handleToggleTagAssing: TagsReducer<IHandleToggleTagAssing> = (
  state,
  action
) => {
  const { tagName, checked } = action.payload;
  const tag = state.converTags.find(t => t.tagName === tagName);
  if (!tag) {
    // eslint-disable-next-line no-console
    console.error(`Tag ${tagName} not found`);
    return;
  }
  state.forceToUpdateTagsList = true;
  tag.assigned = checked;
};

const handlesetForceToUpdateTagList: TagsReducer<boolean> = (state, action) => {
  state.forceToUpdateTagsList = action.payload;
};
interface IHandleFetchContactTags {
  type: TagsType;
}

const handleFetchContactTags: TagsReducer<IHandleFetchContactTags> = state => {
  state.loading.push('fetch-contact-tags');
  state.error = null;
};

const handleFetchContactTagsSuccess: TagsReducer<NewTag[]> = (
  state,
  action
) => {
  state.contactTags = action.payload;
};

const handleFetchContactTagsFailure: TagsReducer<ErrorData> = (
  state,
  action
) => {
  state.error = { ...action.payload, errorCode: 'FETCH ERROR' };
  state.contactError = ContactError.FETCH;
};

const handleFetchContactTagsFulfill: TagsReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-contact-tags');
};

const handleCreateContactTag: TagsReducer = state => {
  state.loading.push('create-contact-tag');
  state.error = null;
  state.success = null;
};

const handleCreateContactTagSuccess: TagsReducer<NewTag> = (state, action) => {
  state.tag = action.payload;
  state.success = ContactSuccess.CREATE;
};

const handleCreateContactTagFailure: TagsReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
  state.contactError = ContactError.CREATE;
  state.success = false;
};

const handleCreateContactTagFulfill: TagsReducer = state => {
  state.loading = state.loading.filter(l => l !== 'create-contact-tag');
};

const handleUpdateContactTag: TagsReducer = state => {
  state.loading.push('update-contact-tag');
  state.error = null;
};

const handleUpdateContactTagSuccess: TagsReducer<NewTag> = (state, action) => {
  state.tag = action.payload;
  state.success = ContactSuccess.EDIT;
};

const handleUpdateContactTagFailure: TagsReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
  state.contactError = ContactError.EDIT;
};

const handleUpdateContactTagFulfill: TagsReducer = state => {
  state.loading = state.loading.filter(l => l !== 'update-contact-tag');
};

const handleDeleteContactTag: TagsReducer<{ tagId: number }> = state => {
  state.loading.push('delete-contact-tag');
  state.error = null;
};

const handleDeleteContactTagFailure: TagsReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
  state.contactError = ContactError.DELETE;
};

const handleDeleteContactTagFulfill: TagsReducer = state => {
  state.loading = state.loading.filter(l => l !== 'delete-contact-tag');
};

interface IHandleAssignToContactTags {
  contactId: number;
  tagIds: number[];
  chatId?: string;
}

const handleAssignToContactTags: TagsReducer<
  IHandleAssignToContactTags
> = state => {
  state.loading.push('assign-contact-tags');
  state.error = null;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const handleAssignToContactTagsSuccess: TagsReducer = () => {};

const handleAssignToContactTagsFailure: TagsReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

const handleAssignToContactTagsFulfill: TagsReducer = state => {
  state.loading = state.loading.filter(l => l !== 'assign-contact-tags');
};

const handleFetchContactTagsById: TagsReducer<{
  contactId: number;
}> = state => {
  state.error = null;
  state.loading.push('fetch-contact-tags-by-id');
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const handleFetchContactTagsByIdSuccess: TagsReducer<NewTag[]> = (
  state,
  action
) => {
  state.contactTagsById = action.payload;
};

const handleFetchContactTagsByIdFailure: TagsReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

const handleFetchContactTagsByIdFulfill: TagsReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-contact-tags-by-id');
};

interface IHandleUnassignToContactTags {
  contactId: number;
  tagId: number;
  chatId?: string;
}

const handleUnassignToContactTags: TagsReducer<
  IHandleUnassignToContactTags
> = state => {
  state.loading.push('unassign-contact-tags');
  state.error = null;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const handleUnassignToContactTagsSuccess: TagsReducer = () => {};

const handleUnassignToContactTagsFailure: TagsReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
  state.contactError = ContactError.UNASSIGN;
};

const handleUnassignToContactTagsFulfill: TagsReducer = state => {
  state.loading = state.loading.filter(l => l !== 'unassign-contact-tags');
};

const handleCleanContactTagsById: TagsReducer = state => {
  state.contactTagsById = [];
};

const handleCleanAndFetchContactTagsById: TagsReducer<{
  contactId: number;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}> = () => {};

const tagsSlice = createSlice({
  name: 'TagsSlice',
  initialState,
  reducers: {
    fetchContactTags: handleFetchContactTags,
    fetchContactTagsSuccess: handleFetchContactTagsSuccess,
    fetchContactTagsFailure: handleFetchContactTagsFailure,
    fetchContactTagsFulfill: handleFetchContactTagsFulfill,
    createContactTag: handleCreateContactTag,
    createContactTagSuccess: handleCreateContactTagSuccess,
    createContactTagFailure: handleCreateContactTagFailure,
    createContactTagFulfill: handleCreateContactTagFulfill,
    updateContactTag: handleUpdateContactTag,
    updateContactTagSuccess: handleUpdateContactTagSuccess,
    updateContactTagFailure: handleUpdateContactTagFailure,
    updateContactTagFulfill: handleUpdateContactTagFulfill,
    deleteContactTag: handleDeleteContactTag,
    deleteContactTagFailure: handleDeleteContactTagFailure,
    deleteContactTagFulfill: handleDeleteContactTagFulfill,
    fetchConverTags: handleFetchConverTags,
    requestConverTagsError: handleRequestConverTagsError,
    tagOnChangeAttr: handleTagOnChangeAttr,
    setIsNewTag: handleSetIsNewTag,
    resetTagForm: handleResetTagForm,
    resetTagError: handleResetTagError,
    resetTagSuccess: handleResetTagSuccess,
    toggleTagAssign: handleToggleTagAssing,
    setForceToUpdateTagList: handlesetForceToUpdateTagList,
    assignToContactTags: handleAssignToContactTags,
    assignToContactTagsSuccess: handleAssignToContactTagsSuccess,
    assignToContactTagsFailure: handleAssignToContactTagsFailure,
    assignToContactTagsFulfill: handleAssignToContactTagsFulfill,
    unassignToContactTags: handleUnassignToContactTags,
    unassignToContactTagsSuccess: handleUnassignToContactTagsSuccess,
    unassignToContactTagsFailure: handleUnassignToContactTagsFailure,
    unassignToContactTagsFulfill: handleUnassignToContactTagsFulfill,
    fetchContactTagsById: handleFetchContactTagsById,
    fetchContactTagsByIdSuccess: handleFetchContactTagsByIdSuccess,
    fetchContactTagsByIdFailure: handleFetchContactTagsByIdFailure,
    fetchContactTagsByIdFulfill: handleFetchContactTagsByIdFulfill,
    cleanContactTagsById: handleCleanContactTagsById,
    cleanAndFetchContactTagsById: handleCleanAndFetchContactTagsById,
  },
});

const { actions, reducer } = tagsSlice;

export const {
  fetchContactTags,
  fetchContactTagsSuccess,
  fetchContactTagsFailure,
  fetchContactTagsFulfill,
  createContactTag,
  createContactTagSuccess,
  createContactTagFailure,
  createContactTagFulfill,
  updateContactTag,
  updateContactTagSuccess,
  updateContactTagFailure,
  updateContactTagFulfill,
  deleteContactTag,
  deleteContactTagFailure,
  deleteContactTagFulfill,
  fetchConverTags,
  requestConverTagsError,
  tagOnChangeAttr,
  setIsNewTag,
  resetTagForm,
  resetTagError,
  resetTagSuccess,
  toggleTagAssign,
  setForceToUpdateTagList,
  assignToContactTags,
  assignToContactTagsSuccess,
  assignToContactTagsFailure,
  assignToContactTagsFulfill,
  unassignToContactTags,
  unassignToContactTagsSuccess,
  unassignToContactTagsFailure,
  unassignToContactTagsFulfill,
  fetchContactTagsById,
  fetchContactTagsByIdSuccess,
  fetchContactTagsByIdFailure,
  fetchContactTagsByIdFulfill,
  cleanContactTagsById,
  cleanAndFetchContactTagsById,
} = actions;

export default reducer;
