import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { WhatsappTemplateComponent } from '@src/components/AdminHome/Channels/WhatsApp/WhatsAppTemplateType';
// eslint-disable-next-line import/no-cycle
import {
  ErrorPayload,
  WhatsAppTemplate,
  WhatsAppTemplateState,
} from '@src/types';

export type WhatsAppTemplatesState = {
  filter: {
    botAccountId: string;
    offset: number;
    limit: number;
    total: number;
    columnOrder: string;
    order: 'asc' | 'desc';
    name?: string;
    states: WhatsAppTemplateState[];
  };
  data: WhatsAppTemplate[];
  error?: ErrorPayload;
  loading: ('pending' | 'success' | 'failure' | 'fulfill')[];
};

const initialState: WhatsAppTemplatesState = {
  filter: {
    botAccountId: '',
    columnOrder: 'createdAt',
    order: 'desc',
    limit: 10,
    offset: 0,
    total: 0,
    states: ['ACTIVE', 'INACTIVE', 'PENDING', 'REJECTED'],
  },
  data: [],
  loading: [],
};

const whatsAppTemplatesSlice = createSlice({
  name: 'whatsAppTemplates',
  initialState,
  reducers: {
    fetchWhatsAppTemplates(
      state,
      action: PayloadAction<{
        filter: WhatsAppTemplatesState['filter'];
      }>
    ) {
      state.loading = ['pending'];
      state.filter = action.payload.filter;
    },
    fetchWhatsAppTemplatesSuccess(
      state,
      action: PayloadAction<{ total: number; data: WhatsAppTemplate[] }>
    ) {
      state.filter = {
        ...state.filter,
        total: action.payload.total,
      };
      action.payload.data.forEach(addTemplateComponentRecordProperty);
      state.data = action.payload.data;
      state.loading = ['success'];
    },
    fetchWhatsAppTemplatesFailure(
      state,
      action: PayloadAction<{ error: ErrorPayload }>
    ) {
      state.data = [];
      state.error = action.payload.error;
      state.loading = ['failure'];
    },
    fetchWhatsAppTemplatesFulfill(state) {
      state.loading = [
        ...state.loading.filter(v => v !== 'pending'),
        'fulfill',
      ];
    },
    updateWhatsAppTemplateStatus(
      state,
      action: PayloadAction<{
        accountId: number;
        name: string;
        status: WhatsAppTemplateState;
      }>
    ) {
      const { accountId, name, status } = action.payload;

      state.data.find((template, index) => {
        if (template.botAccountId === accountId && template.name === name) {
          state.data[index].status = status;
          return true;
        }
        return false;
      });
    },
  },
});

export default whatsAppTemplatesSlice.reducer;

export const {
  fetchWhatsAppTemplates,
  fetchWhatsAppTemplatesSuccess,
  fetchWhatsAppTemplatesFailure,
  fetchWhatsAppTemplatesFulfill,
  updateWhatsAppTemplateStatus,
} = whatsAppTemplatesSlice.actions;

export function addTemplateComponentRecordProperty(item: WhatsAppTemplate) {
  item.components = item.templateComponent as WhatsappTemplateComponent[];
  item.templateComponentRecord = {};
}
