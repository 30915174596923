/* eslint-disable no-shadow */
// FIXME: Migrate rest of the file to TS
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  facebookMessengerLogo,
  facebookMessengerSmallLogo,
  instagramLogo,
  instagramSmallLogo,
  livechatLogo,
  livechatSmallLogo,
  logoB2CBotApi,
  smsLogo,
  telegramLogo,
  telegramSmallLogo,
  whatsappBusinessSmallLogo,
  whatsappLogo,
  whatsappSmallLogo,
} from '../resources';
// A map that defines the events that might occur on an agent chat
export const EventType = {
  NEW_CHAT: { id: 'NEW_CHAT' },
  SWITCHED_ACTIVE_CHAT: { id: 'SWITCHED_ACTIVE_CHAT' },
  INCOMING_MESSAGE: { id: 'INCOMING_MESSAGE' },
  OUTGOING_MESSAGE: { id: 'OUTGOING_MESSAGE' },
  AGENT_PICKED_UP_CHAT: { id: 'AGENT_PICKED_UP_CHAT' },
  AGENT_CLOSED_CHAT: { id: 'AGENT_CLOSED_CHAT' },
  MESSAGE_SENT: { id: 'MESSAGE_SENT' },
  MESSAGE_READ: { id: 'MESSAGE_READ' },
  CONTACT_CLOSED_CHAT: { id: 'CONTACT_CLOSED_CHAT' },
  CONTACT_LEFT_CHAT: { id: 'CONTACT_LEFT_CHAT' },
  REOPENED_CHAT: { id: 'REOPENED_CHAT' },
  OUT_MESSAGE_DELIVERY_CONFIRMED: { id: 'OUT_MESSAGE_DELIVERY_CONFIRMED' },
  OUT_MESSAGE_DELIVERY_FAILED: { id: 'OUT_MESSAGE_DELIVERY_FAILED' },
  OUT_MESSAGE_RECEIPT: { id: 'OUT_MESSAGE_RECEIPT' },
  OUT_MESSAGE_READ: { id: 'OUT_MESSAGE_READ' },
  IN_MESSAGE_DELIVERY_CONFIRMED: { id: 'IN_MESSAGE_DELIVERY_CONFIRMED' },
  IN_MESSAGE_DELIVERY_FAILED: { id: 'IN_MESSAGE_DELIVERY_FAILED' },
  IN_MESSAGE_READ: { id: 'IN_MESSAGE_READ' },
  XFER_REQUESTED: { id: 'XFER_REQUESTED' },
  XFER_CANCELLED: { id: 'XFER_CANCELLED' },
  XFER_REJECTED: { id: 'XFER_REJECTED' },
  RESUME_REQUESTED: { id: 'RESUME_REQUESTED' },
  XFER_ACCEPTED: { id: 'XFER_ACCEPTED' },
  CHAT_FORCED_ASSIGNED_TO_AGENT: { id: 'CHAT_FORCED_ASSIGNED_TO_AGENT' },
  NEW_CONVERSATION: { id: 'NEW_CONVERSATION' },
  NEW_AUTOMATIC_MESSAGE: { id: 'NEW_AUTOMATIC_MESSAGE' },
  CHAT_PIN_TOGGLED: { id: 'CHAT_PIN_TOGGLED' },
  INCOMING_REACTION: { id: 'INCOMING_REACTION' },
  CHAT_TAKEN: { id: 'CHAT_TAKEN' },
  CHAT_TAKEN_MESSAGE: { id: 'CHAT_TAKEN_MESSAGE' },
};

// An "enumeration" that defines all supported messaging providers
// eslint-disable-next-line no-shadow
export const enum MessagingProvider {
  B2CBOTAPI = 'B2CBOTAPI',
  BANDWIDTH = 'BANDWIDTH',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  LIVECHAT = 'LIVECHAT',
  TELEGRAM = 'TELEGRAM',
  /**
   * @deprecated
   * Unofficial provider
   */
  WHATSAPP = 'WHATSAPP',
  /** 360 Dialog */
  WHATSAPP360 = 'WHATSAPP360',
  /** Botmaker */
  WHATSAPPBM = 'WHATSAPPBM',
  /**
   * @deprecated
   * Unofficial provider Chat API
   */
  WHATSAPPCHAT = 'WHATSAPPCHAT',
  /** Smooch Sunshine */
  WHATSAPPOFF = 'WHATSAPPOFF',
  /**
   * @deprecated
   * Unofficial provider
   */
  WHATSAPPWEB = 'WHATSAPPWEB',
  /** B2Chat as WhatsApp BSP */
  WHATSAPPB2CHAT = 'WHATSAPPB2CHAT',
}

// An "enumeration" that defines all supported messaging providers
export const MessagingProviderLabel: Record<MessagingProvider, string> = {
  B2CBOTAPI: 'B2Chat API',
  BANDWIDTH: 'BANDWIDTH',
  FACEBOOK: 'Facebook Messenger',
  INSTAGRAM: 'Instagram',
  LIVECHAT: 'Livechat',
  TELEGRAM: 'Telegram',
  WHATSAPP360: 'WhatsApp 360 Dialog',
  WHATSAPP: 'WhatsApp',
  WHATSAPPBM: 'WhatsApp BotMaker',
  WHATSAPPCHAT: 'WhatsApp ChatAPI',
  WHATSAPPOFF: 'WhatsApp Sunshine',
  WHATSAPPWEB: 'WhatsApp Web',
  WHATSAPPB2CHAT: 'WhatsApp B2Chat',
};

export const MessagingProviderSimpleName: Record<MessagingProvider, string> = {
  B2CBOTAPI: 'b2chatapi',
  BANDWIDTH: 'bandwidth',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  LIVECHAT: 'livechat',
  TELEGRAM: 'telegram',
  WHATSAPP360: 'whatsapp',
  WHATSAPP: 'whatsapp',
  WHATSAPPBM: 'whatsapp',
  WHATSAPPCHAT: 'whatsapp',
  WHATSAPPOFF: 'whatsapp',
  WHATSAPPWEB: 'whatsapp',
  WHATSAPPB2CHAT: 'whatsapp',
};

// A Mapping of the messaging provider codes to their corresponding logo images
export const MessagingProviderSmallIcon: Record<MessagingProvider, string> = {
  B2CBOTAPI: logoB2CBotApi,
  BANDWIDTH: smsLogo,
  FACEBOOK: facebookMessengerSmallLogo,
  INSTAGRAM: instagramSmallLogo,
  LGUARDI: whatsappSmallLogo,
  LIVECHAT: livechatSmallLogo,
  TELEGRAM: telegramSmallLogo,
  WHATSAPP: whatsappSmallLogo,
  WHATSAPP360: whatsappSmallLogo,
  WHATSAPPBM: whatsappBusinessSmallLogo,
  WHATSAPPCHAT: whatsappSmallLogo,
  WHATSAPPOFF: whatsappSmallLogo,
  WHATSAPPWEB: whatsappSmallLogo,
  WHATSAPPB2CHAT: whatsappSmallLogo,
};

export const MessagingProviderIcon: Record<MessagingProvider, string> = {
  B2CBOTAPI: logoB2CBotApi,
  BANDWIDTH: smsLogo,
  FACEBOOK: facebookMessengerLogo,
  INSTAGRAM: instagramLogo,
  LGUARDI: whatsappLogo,
  LIVECHAT: livechatLogo,
  TELEGRAM: telegramLogo,
  WHATSAPP: whatsappLogo,
  WHATSAPP360: whatsappLogo,
  WHATSAPPBM: whatsappLogo,
  WHATSAPPCHAT: whatsappLogo,
  WHATSAPPOFF: whatsappLogo,
  WHATSAPPWEB: whatsappLogo,
  WHATSAPPB2CHAT: whatsappLogo,
};

// Possible states a chat may be in
export const ChatStatus = {
  OPENED: 'OPENED',
  PICKED_UP: 'PICKED_UP',
  CLOSED: 'CLOSED',
  CLOSED_BY_CONTACT: 'CLOSED_BY_CONTACT',
  LEFT_BY_CONTACT: 'LEFT_BY_CONTACT',
  BOT_CHATTING: 'BOT_CHATTING',
  COMPLETED_POLL: 'COMPLETED_POLL',
  ABANDONED_POLL: 'ABANDONED_POLL',
  COMPLETING_POLL: 'COMPLETING_POLL',
  ALL: 'ALL',
  NOT_CLOSED: 'NOT_CLOSED',
  RESPONDED_BY_AGENT: 'RESPONDED_BY_AGENT',
  SATISFACTION_BOT: 'SATISFACTION_BOT',
};

// Possible states a message may be in
export const MessageStatus = {
  TRANSMITTING_MEDIA: 'TRANSMITTING_MEDIA',
  IN_DELIVERY: 'IN_DELIVERY',
  DELIVERED: 'DELIVERED',
  FAILED: 'FAILED',
  DELIVERY_TIMED_OUT: 'DELIVERY_TIMED_OUT',
};

// Possible ways to sort the list of incoming/ongoing chats, as specified via preferences
export const SortingMode = {
  OLDEST_CREATED_FIRST: 'OLDEST_CREATED_FIRST',
  LAST_RECEIVED_MESSAGE_FIRST: 'LAST_RECEIVED_MESSAGE_FIRST',
  LAST_SENT_MESSAGE_LAST: 'LAST_SENT_MESSAGE_LAST',
};

// Possible states a bot-account may be in
export const BotAccountStates = {
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
};

const MimeTypeExtensionMapping = {
  'audio/mp3': '.mp3',
};

export function fromMimeTypeToExtension(mimeType: string) {
  return MimeTypeExtensionMapping[
    mimeType as keyof typeof MimeTypeExtensionMapping
  ];
}

export function isWhatsAppOffMsgProvider(messagingProvider: MessagingProvider) {
  return [
    MessagingProvider.WHATSAPPOFF,
    MessagingProvider.WHATSAPPBM,
    MessagingProvider.WHATSAPP360,
    MessagingProvider.WHATSAPPB2CHAT,
  ].includes(messagingProvider);
}

export function providerHasConfigPage(messagingProvider: MessagingProvider) {
  return (
    MessagingProvider.FACEBOOK === messagingProvider ||
    MessagingProvider.TELEGRAM === messagingProvider ||
    MessagingProvider.LIVECHAT === messagingProvider ||
    MessagingProvider.WHATSAPPOFF === messagingProvider ||
    MessagingProvider.INSTAGRAM === messagingProvider ||
    MessagingProvider.WHATSAPPB2CHAT === messagingProvider
  );
}

export function isNoOffWappMsgProvider(messagingProvider: MessagingProvider) {
  return (
    MessagingProvider.WHATSAPP === messagingProvider ||
    MessagingProvider.WHATSAPPCHAT === messagingProvider
  );
}

export function isWappMsgProvider(messagingProvider: MessagingProvider) {
  return (
    isNoOffWappMsgProvider(messagingProvider) ||
    isWhatsAppOffMsgProvider(messagingProvider)
  );
}

export function supportsMultipleFileTypes(
  messagingProvider: MessagingProvider
) {
  return (
    isWappMsgProvider(messagingProvider) ||
    MessagingProvider.WHATSAPPCHAT === messagingProvider
  );
}

export function supportsVoiceMessage(messagingProvider: MessagingProvider) {
  return [
    MessagingProvider.FACEBOOK,
    MessagingProvider.TELEGRAM,
    MessagingProvider.WHATSAPPBM,
    MessagingProvider.WHATSAPPOFF,
    MessagingProvider.WHATSAPP,
    MessagingProvider.INSTAGRAM,
    MessagingProvider.WHATSAPP360,
    MessagingProvider.WHATSAPPB2CHAT,
    MessagingProvider.B2CBOTAPI,
  ].includes(messagingProvider);
}

// Codes to refer to all possible types of result an async request might have
export const ResponseStatusCode = {
  OK: 'OK',
  ERROR: 'ERROR',
  TIMEOUT: 'TIMEOUT',
};

// eslint-disable-next-line no-shadow
export enum MediaType {
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  FILE = 'FILE',
  LOCATION = 'LOCATION',
  TEXT = 'TEXT',
  PRODUCT_OVERVIEW = 'PRODUCT_OVERVIEW',
  STICKER = 'STICKER',
  AD = 'AD',
  SHOPPING_CART = 'SHOPPING_CART',
  CATALOG = 'CATALOG',
  CART = 'CART',
  ORDER = 'ORDER',
  INTERACTIVE_LIST = 'INTERACTIVE_LIST',
}

export const ServiceConnectors = {
  KEENIO: 'KEENIO',
  ZENDESK: 'ZENDESK',
  FRESHDESK: 'FRESHDESK',
};

export function Bot(id, name) {
  this.id = id;
  this.name = name;
}

export function Agent(id, login, name) {
  this.id = id;
  this.login = login;
  this.fullName = name;
}

export function Contact(id, fullName, isNewContact) {
  this.id = id;
  this.fullName = fullName;
  this.identificationNumber = null;
  this.email = null;
  this.phoneNumber = null;
  this.mobileNumber = null;
  this.isNewContact = typeof isNewContact === 'undefined' ? true : isNewContact;
}

export function MessagingAccount(contactId, accountId, messagingProvider) {
  this.uid = null;
  this.id = `${accountId}@${messagingProvider}`;
  this.accountId = accountId;
  this.contactId = contactId;
  this.provider = messagingProvider;
  this.avatarUrl = null;
}

// A message, part of a chat
export function Message(
  messageId: string,
  chatId: string,
  from: string | number,
  to: string | number,
  text: string,
  messagingProvider: MessagingProvider,
  sendingTime = Date.now()
) {
  this.messageId = messageId;
  this.chatId = chatId;
  this.from = from;
  this.to = to;
  this.sendingTime = sendingTime;
  this.messagingProvider = messagingProvider;
  this.text = text;
  this.status = MessageStatus.IN_DELIVERY;
}

export function AgentChat(
  chatId,
  contactAccountId,
  agentId,
  queueId,
  botId,
  botName
) {
  this.id = chatId;
  this.contactAccountId = contactAccountId;
  this.agentId = agentId;
  this.queueId = queueId;
  this.botId = botId;
  this.botName = botName;
  this.startTimestamp = null;
  this.crmTicketId = null;
  this.crmProvider = null;
  this.viaBotAccount = null;
  this.viaBotAccountAlias = null;
  this.events = []; // Also defines the sequence of events by listing them from the foremost to the latest
}

// Represents events related to agent chats. The eventType argument must be an attribute of the EventType map,
// payload must be an instance of either AgentChat or AgentChatMessage
export function AgentChatEvent(eventType, eventData) {
  this.type = eventType;
  this.payload = eventData;
}

export function UserChatEvent(eventType, eventData) {
  this.type = eventType;
  this.payload = eventData;
}

// Represents the initial status message sent by the backend when connection is established
// eslint-disable-next-line @typescript-eslint/no-empty-function
export function AgentChatStatus() {}

// Messages sent by the backend as response to requests
export function ResponseEvent() {
  this.requestId = null;
  this.status = null;
  this.response = null;
  this.error = null;
}

//A message, part of a chat
export function Filter(
  merchantId,
  agentId,
  contactValue,
  startDate,
  endDate,
  text,
  activeChat,
  timeframe,
  contactId,
  searchType
) {
  this.merchantId = merchantId;
  this.agentId = agentId;
  this.contactValue = contactValue;
  this.startDate = startDate;
  this.endDate = endDate;
  this.text = text;
  this.activeChat = activeChat;
  this.timeframe = timeframe;
  this.contactId = contactId;
  this.searchType = searchType;
}

export function Merchant(merchantName) {
  this.name = merchantName;
}

export function ContactOrder(contact) {
  this.address = contact.address;
  this.city = contact.city;
  this.email = contact.email;
  this.fullName = contact.fullName;
  this.id = contact.id;
  this.identificationNumber = contact.identificationNumber;
  this.mobileNumber = contact.mobileNumber;
  this.phoneNumber = contact.phoneNumber;
}

export function OrderItem(product, quantity) {
  this.reference = product;
  this.quantity = quantity;
}

export function Order(merchant, contactOrder, orderItems) {
  this.merchant = merchant;
  this.contactOrder = contactOrder;
  this.listOrderItem = orderItems;
}

export const dialogTitleTypes = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFORMATION: 'INFORMATION',
};

export function UserFilter(userValue, status) {
  this.userValue = userValue;
  this.status = status;
}

export function User(
  id,
  fullName,
  mobileNumber,
  email,
  role,
  userName,
  password,
  matchPassword,
  token,
  acceptsTerms,
  acceptsDataPrivacyPolicy,
  avatar,
  mobileAccess,
  chatDeletePolicy
) {
  this.id = id;
  this.fullName = fullName;
  this.mobileNumber = mobileNumber;
  this.email = email;
  this.role = role;
  this.userName = userName;
  this.password = password;
  this.matchPassword = matchPassword;
  this.token = token;
  this.acceptsTerms = acceptsTerms;
  this.acceptsDataPrivacyPolicy = acceptsDataPrivacyPolicy;
  this.avatar = avatar;
  this.mobileAccess = mobileAccess;
  this.chatDeletePolicy = chatDeletePolicy;
}

export function StandardMessage(value, code, enabled) {
  this.value = value;
  this.code = code;
  this.enabled = enabled;
}

// eslint-disable-next-line no-shadow
export enum AgentChatTransferStatus {
  REQUESTED = 'REQUESTED',
  PICKED_UP = 'PICKED_UP',
  CANCELLED = 'CANCELLED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  CLEANED = 'CLEANED',
}

export function AgentChatTransfer(params) {
  this.chatId = params.chatId || null;
  this.status = params.status || null;
  this.comments = params.comments || null;
  this.rejectReason = params.rejectReason || null;
  this.startTimestamp = params.startTimestamp || null;
  this.notified = params.notified || null;
  this.sourceAgent = params.sourceAgent || null;
  this.targetAgent = params.targetAgent || null;
  this.targetBizProcess = params.targetBizProcess || null;
}

export function BillingAddress(params) {
  this.id = null;
  this.name = params.form.companyText;
  this.identification = params.form.identificationNumberText;
  this.emails = params.form.emailsText;
  this.address = params.form.addressText;
  this.country = params.form.countryText;
  this.province = params.form.provinceText;
  this.city = params.form.cityText;
  this.currency = params.form.currency;
}
