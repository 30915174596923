import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ErrorData } from '@types';

type Loading = 'fetch-url';

export type URLPreview = {
  url: string;
  urlImage: string;
  title: string;
  description: string;
};

export type UrlItems = {
  [k: string]: URLPreview;
};

type UrlPreviewState = {
  error: ErrorData | null;
  loading: Loading[];
  items: UrlItems;
};

type UrlPreviewReducer<P = void> = (
  state: UrlPreviewState,
  action: PayloadAction<P>
) => void;

const initialState: UrlPreviewState = {
  error: null,
  loading: [],
  items: {},
};

type FetchUrlPayload = {
  url: string;
};

const handleFetchUrl: UrlPreviewReducer<FetchUrlPayload> = state => {
  state.loading.push('fetch-url');
  state.error = null;
};

const handleFetchUrlSuccess: UrlPreviewReducer<URLPreview> = (
  state,
  action
) => {
  const item = action.payload;
  const { url } = item;
  if (state.items) {
    state.items[url] = item;
  } else {
    state.items = {
      url: item,
    };
  }
};

const handleFetchUrlFailure: UrlPreviewReducer<ErrorData> = (state, action) => {
  state.error = action.payload;
};

const handleFetchUrlFulfill: UrlPreviewReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-url');
};

const urlPreviewSlice = createSlice({
  name: 'UrlPreview',
  initialState,
  reducers: {
    fetchUrl: handleFetchUrl,
    fetchUrlSuccess: handleFetchUrlSuccess,
    fetchUrlFailure: handleFetchUrlFailure,
    fetchUrlFulfill: handleFetchUrlFulfill,
  },
});

const { actions, reducer } = urlPreviewSlice;

export const { fetchUrl, fetchUrlSuccess, fetchUrlFailure, fetchUrlFulfill } =
  actions;

export default reducer;
