/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TapiChartReport, TfailedApiChart } from '@reducers/deliveriesMassive';
import { ApiChartColor, BarLegend, ReasonsFailure } from '@src/types';

const language = window.navigator.language.split(/[-_]/)[0];

const getMonth = (position: number) =>
  language === 'es'
    ? [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ].at(Number(position))
    : [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ].at(Number(position));

const chartjs2ApiReport = (
  chart: TapiChartReport,
  failedChart: TfailedApiChart,
  rangeCode?: string
) => {
  let datasets: any = [];
  let labels = failedChart ? failedChart.labels : chart?.labels || [];

  if (failedChart) {
    const { failedMessagesPerReasonsMap: error } = failedChart;

    datasets = Object.keys(error).map((key, index) => ({
      label: ReasonsFailure[key] || key,
      data: error[key] || [],
      backgroundColor: [Object.values(ApiChartColor)[index]],
    }));
  } else {
    const noDelivered = chart?.sendMessages.map((sendValue, index) => {
      const deliveredValue = chart?.deliveredMessages[index];
      return sendValue - deliveredValue;
    });

    datasets = [
      {
        label: BarLegend.failed.label,
        data: chart?.failedMessages || [],
        backgroundColor: [ApiChartColor.RED],
      },
      {
        label: BarLegend.delivered.label,
        data: chart?.deliveredMessages || [],
        backgroundColor: [ApiChartColor.GREEN],
      },
      {
        label: BarLegend.read.label,
        data: chart?.readsMessages || [],
        backgroundColor: [ApiChartColor.PURPLE],
      },
      {
        label: BarLegend.noDelivered.label,
        data: noDelivered || [],
        backgroundColor: [ApiChartColor.LIGHTGRAY],
      },
      {
        label: BarLegend.answers.label,
        data: chart?.repliedMessages || [],
        backgroundColor: [ApiChartColor.ORANGE],
      },
    ];
  }

  if (rangeCode?.includes('m')) {
    labels = labels.map(date => {
      const [month, year] = date.split('/');
      const index = Number(month) - 1;
      const formatMonth = getMonth(index);
      return `${formatMonth}/${year}`;
    });
  }

  if (rangeCode?.includes('d')) {
    labels = labels.map(date => {
      const [day, month] = date.split('/');
      return `${day}/${month}`;
    });
  }

  if (rangeCode?.includes('h')) {
    labels = labels.map(date => {
      const hour = date.split(' ')[1];
      return hour;
    });
  }

  let maxScaleY = Math.max(
    ...datasets.reduce(
      (curr: any, item: any) => [...curr, Math.max(...item.data)],
      []
    )
  );

  const value = maxScaleY % 10;
  maxScaleY = value === 0 ? maxScaleY : 10 - value + maxScaleY;

  return {
    config: {
      labels,
      datasets,
    },
    options: {
      categoryPercentage: 0.7,
      barPercentage: 0.7,
      barThickness: 'flex',
      elements: {
        bar: {
          borderRadius: 5,
        },
      },
      aspectRatio: 0.9,
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 40,
          bottom: 10,
        },
      },
      scales: {
        x: {
          display: true,
          grid: {
            display: true,
          },
          ticks: {
            color: '#A1A1AA',
          },
        },
        y: {
          ticks: {
            stepSize: maxScaleY === 0 ? 1 : Math.ceil(maxScaleY / 3),
            color: '#ADB8CC',
          },
        },
      },
      plugins: {
        tooltip: {
          yAlign: 'bottom',
          displayColors: false,
          padding: 10,
          bodyFont: {
            weight: 'bold',
          },
          backgroundColor: (_chart: any) => {
            const isThereOneActive = _chart.tooltipItems.length === 1;
            const BLACK = '#282828';
            return isThereOneActive
              ? _chart.tooltip.labelColors[0].backgroundColor
              : BLACK;
          },
          callbacks: {
            title: () => null,
            label: (tooltipItem: any) =>
              `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`,
          },
        },
        legend: {
          display: true,
          position: 'bottom',
          align: 'start',
          labels: {
            boxWidth: 15,
            boxHeight: 15,
            padding: 20,
            usePointStyle: true,
            pointStyle: 'rectRounded',
          },
        },
      },
    },
  };
};

export default chartjs2ApiReport;
