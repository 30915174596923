// Extract the time at which the last message was sent by the agent in a chat
const fnLastChatSentMsgTime = c =>
  c.lastMsgSentAt ? c.lastMsgSentAt : c.startTimestamp;

const fnSortLastMsgReceivedFirst = (a, b) => {
  const isPinned1 = a.pinId;
  const isPinned2 = b.pinId;

  if (isPinned1 || isPinned2) {
    if (isPinned1 && isPinned2) {
      return isPinned1 > isPinned2 ? -1 : 1;
    }
    return isPinned1 ? -1 : 1;
  }

  // Position agent chat transfers at the top
  if (a.chatXferRequest) return -1;
  if (b.chatXferRequest) return 1;

  let rankA = fnLastChatMsgTime(a);
  let rankB = fnLastChatMsgTime(b);

  rankA = rankA || 0;
  rankB = rankB || 0;

  if (rankA < rankB) {
    return 1;
  }
  if (rankA > rankB) {
    return -1;
  }

  return 0;
};

// Extract the time at which the last message was received or sent in a chat
const fnLastChatMsgTime = c => {
  const lastRcptTime = c.lastMsgReceivedAt
    ? c.lastMsgReceivedAt
    : c.startTimestamp;
  const lastSendTime = c.lastMsgSentAt ? c.lastMsgSentAt : c.startTimestamp;

  return lastRcptTime >= lastSendTime ? lastRcptTime : lastSendTime;
};

// Define the sorting functions corresponding to each criterion
const fnSortOldestCreatedFirst = (a, b) => {
  // Position agent chat transfers at the top
  if (a.chatXferRequest) return -1;
  if (b.chatXferRequest) return 1;

  const rankA = a.startTimestamp ? a.startTimestamp : 0;
  const rankB = b.startTimestamp ? b.startTimestamp : 0;

  if (rankA > rankB) {
    return 1;
  }
  if (rankA < rankB) {
    return -1;
  }
  return 0;
};

const fnSortLastMsgSentLast = (a, b) => {
  // Position agent chat transfers at the top
  if (a.chatXferRequest) return -1;
  if (b.chatXferRequest) return 1;

  let rankA = fnLastChatSentMsgTime(a);
  let rankB = fnLastChatSentMsgTime(b);

  rankA = rankA || 0;
  rankB = rankB || 0;

  if (rankA > rankB) {
    return 1;
  }
  if (rankA < rankB) {
    return -1;
  }

  return 0;
};

// Define the sorting criteria and the sorting function to be used in each case
// NOTE: Sorting modes are defined at mode/frontendmodel.js:SortingMode
export const sortingModes = {
  OLDEST_CREATED_FIRST: { sortingFunction: fnSortOldestCreatedFirst },
  LAST_RECEIVED_MESSAGE_FIRST: {
    sortingFunction: fnSortLastMsgReceivedFirst,
  },
  LAST_SENT_MESSAGE_LAST: { sortingFunction: fnSortLastMsgSentLast },
};

// Maps the name of a sorting mode to an object containing its respective sorting function
export const fnGetSortingMode = sortModeName => {
  let chosenSortingMode = sortingModes[sortModeName];

  if (!chosenSortingMode) {
    // Use last-message-first as default sorting mode
    chosenSortingMode = sortingModes.LAST_RECEIVED_MESSAGE_FIRST;
  }

  return chosenSortingMode;
};

const ChatSorting = (() => ({
  // Sorts an array of chats in accordance with the specified sorting criterion
  // Returns an array of chat objects, sorted in the specified order
  sortChatArray: (mapChats, mapSelectedChatIds, sortModeName) => {
    const arrSortChatRequests = [];
    const chosenSortingMode = fnGetSortingMode(sortModeName);

    Object.keys(mapSelectedChatIds).forEach(chatId => {
      if (mapChats[chatId]) {
        arrSortChatRequests.push(mapChats[chatId]);
      }
    });

    // Sort the array of chat requests by the appropriate criterion
    arrSortChatRequests.sort(chosenSortingMode.sortingFunction);
    return arrSortChatRequests;
  },

  // Sorts an array of chats in accordance with the specified sorting criterion
  // Returns an array of chat IDs, sorted in the specified order
  sortChatArrayIds: (mapChats, mapSelectedChatIds, sortModeName) => {
    const arrSortedChats = this.sortChatArray(
      mapChats,
      mapSelectedChatIds,
      sortModeName
    );
    return arrSortedChats.map(chat => chat.id);
  },

  // Says whether the first chat is positioned before the second chat, as to the specified sorting mode
  isBeforeAsToSortingMode: (chat1, chat2, sortModeName) => {
    const chosenSortingMode = fnGetSortingMode(sortModeName);
    return chosenSortingMode.sortingFunction(chat1, chat2) < 0;
  },
}))();

export default ChatSorting;
