/* eslint-disable import/export */
import { MessagingProvider } from '@src/model/frontendmodel';
import { RootState } from '@src/types';
import { ChatSideType, ChatTray } from '@src/types/chats';
import { FilterDepartmentNode } from '@src/types/chatsSearch';
import { orderStringList } from '@src/utils/order';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { activeChatSideSelector } from './chats';
import { preferencesSelect } from './preferences';

dayjs.extend(isBetween);

export const departmentsNodesFilterSelector = (state: RootState) =>
  orderStringList<FilterDepartmentNode>(
    state.chatsSearch.departments?.nodes || [],
    'name'
  );

export const tagsFilterSelector = (state: RootState) => state.chatsSearch.tags;

export const botAccountsFilterSelector = (state: RootState) =>
  state.chatsSearch.botAccounts;

export const channelsFilterSelector = (state: RootState) =>
  state.chatsSearch.channels;

export const filtersToRequestPayloadSelector = (state: RootState) => {
  const activeChatSide = activeChatSideSelector(state);
  const {
    contactName = '',
    windowLimit = false,
    conversationLimit = false,
    noAnswerFromAgent = false,
    dateFrom = undefined,
    dateTo = undefined,
    channels = [],
    botAccounts = [],
    departmentNodes = [],
    tags = [],
    contactTags = [],
  } = state.chatsSearch.filters[activeChatSide] || {};
  return {
    contactName,
    windowLimit,
    conversationLimit,
    noAnswerFromAgent,
    dateFrom: dateFrom
      ? dayjs(dateFrom).format('YYYY-MM-DDT00:00:00.000').concat('Z')
      : undefined,
    dateTo: dateTo
      ? dayjs(dateTo).format('YYYY-MM-DDT23:59:59.000').concat('Z')
      : undefined,
    provider: channels.map(({ value }) => value).flat(),
    viaBotAccountId: botAccounts.map(({ id }) => id),
    viaBusinessProcessNode: departmentNodes?.map(({ bot }) => bot.id),
    tags: tags.map(({ tagName, botId }) => ({ tagName, botId })),
    contactTagFilter: contactTags.map(({ tagId }) => tagId),
  };
};

export const isFilteringInActiveSideSelector = (state: RootState) => {
  const {
    contactName = '',
    conversationLimit = false,
    windowLimit = false,
    noAnswerFromAgent = false,
    dateFrom,
    dateTo,
    channels = [],
    botAccounts = [],
    departmentNodes = [],
    tags = [],
    contactTags = [],
  } = activeSideFilters(state);

  return (
    contactName?.length ||
    conversationLimit ||
    windowLimit ||
    noAnswerFromAgent ||
    dateFrom ||
    dateTo ||
    !!channels.length ||
    !!botAccounts.length ||
    !!departmentNodes.length ||
    !!tags.length ||
    !!contactTags.length
  );
};

export const activeSideFilters = (state: RootState) => {
  const activeChatSide = activeChatSideSelector(state);
  return state.chatsSearch.filters[activeChatSide] || {};
};

export const chatsSideFiltersSelector =
  (state: RootState) => (chatSide: ChatSideType) =>
    state.chatsSearch.filters[chatSide] || {};

export const isValidChatToAllFiltersOnHoldSelector =
  (state: RootState) => (chat: ChatTray) =>
    isValidChatToAllFiltersSelector(state)(chat, ChatSideType.ON_HOLD);

export const isValidChatToAllFiltersRequestSelector =
  (state: RootState) => (chat: ChatTray) =>
    isValidChatToAllFiltersSelector(state)(chat, ChatSideType.REQUEST);

export const isValidChatToAllFiltersSelector =
  (state: RootState) => (chat: ChatTray, chatSide: ChatSideType) =>
    isValidChatToContactFilterSelector(state)(chat, chatSide) &&
    isValidChatToConversationLimitFilterSelector(state)(chat, chatSide) &&
    isValidChatToWindowLimitFilterSelector(state)(chat, chatSide) &&
    isValidChatToNoAnswerFromAgentFilterSelector(state)(chat, chatSide) &&
    isValidChatToDateFilterSelector(state)(chat, chatSide) &&
    isValidChatToChannelsFilterSelector(state)(chat, chatSide) &&
    isValidChatToBotAccountsFilterSelector(state)(chat, chatSide) &&
    isValidChatToDepartmentsNodesFilterSelector(state)(chat, chatSide) &&
    isValidChatToTagsFilterSelector(state)(chat, chatSide) &&
    isValidChatToContactTagsFilterSelector(state)(chat, chatSide);

export const isValidChatToContactFilterSelector =
  (state: RootState) =>
  (chat: ChatTray, chatSide: ChatSideType = ChatSideType.ON_HOLD) => {
    const { contactName = '' } = chatsSideFiltersSelector(state)(chatSide);
    const contactNameInChat = chat?.contact?.fullName?.toLowerCase() || '';
    return contactNameInChat.indexOf(contactName.toLowerCase()) !== -1;
  };

export const isValidChatToConversationLimitFilterSelector =
  (state: RootState) =>
  (chat: ChatTray, chatSide: ChatSideType = ChatSideType.ON_HOLD) => {
    const { conversationLimit = false } =
      chatsSideFiltersSelector(state)(chatSide);
    const { conversationFiltersAlarmHours = 1 } = preferencesSelect(state);

    if (!conversationLimit) return true;

    const now = new Date().getTime();
    const from = now - dayjs.duration(24, 'hours').asMilliseconds();
    const to =
      now +
      dayjs.duration(conversationFiltersAlarmHours, 'hours').asMilliseconds() -
      dayjs.duration(24, 'hours').asMilliseconds();

    return dayjs(chat.lastWAConversationStartedAt).isBetween(
      from,
      to,
      'second'
    );
  };

export const isValidChatToWindowLimitFilterSelector =
  (state: RootState) =>
  (chat: ChatTray, chatSide: ChatSideType = ChatSideType.ON_HOLD) => {
    const { windowLimit = false } = chatsSideFiltersSelector(state)(chatSide);
    const { conversationFiltersAlarmHours = 1 } = preferencesSelect(state);
    const allowedProviders = [
      MessagingProvider.WHATSAPPBM,
      MessagingProvider.WHATSAPP360,
      MessagingProvider.WHATSAPPOFF,
      MessagingProvider.WHATSAPPB2CHAT,
    ];

    if (!windowLimit) return true;
    if (!allowedProviders.includes(chat.provider)) return false;

    const now = new Date().getTime();
    const from = now - dayjs.duration(24, 'hours').asMilliseconds();
    const to =
      now +
      dayjs.duration(conversationFiltersAlarmHours, 'hours').asMilliseconds() -
      dayjs.duration(24, 'hours').asMilliseconds();

    return dayjs(chat.lastWAWindowStartedAt).isBetween(from, to, 'second');
  };

export const isValidChatToNoAnswerFromAgentFilterSelector =
  (state: RootState) =>
  (chat: ChatTray, chatSide: ChatSideType = ChatSideType.ON_HOLD) => {
    const { noAnswerFromAgent = false } =
      chatsSideFiltersSelector(state)(chatSide);

    if (!noAnswerFromAgent) return true;

    return (
      chat?.messagesSinceLastResponse || chat.messagesSinceLastResponse > 0
    );
  };

export const isValidChatToDateFilterSelector =
  (state: RootState) =>
  (chat: ChatTray, chatSide: ChatSideType = ChatSideType.ON_HOLD) => {
    const { dateFrom, dateTo } = chatsSideFiltersSelector(state)(chatSide);

    if (!dateFrom) return true;

    const newDateTo = dateTo ? new Date(dateTo) : new Date();
    const isBetweenDate = dayjs(chat.startTimestamp).isBetween(
      dateFrom,
      newDateTo,
      'second'
    );
    return isBetweenDate;
  };

export const isValidChatToChannelsFilterSelector =
  (state: RootState) =>
  (chat: ChatTray, chatSide: ChatSideType = ChatSideType.ON_HOLD) => {
    const { channels = [] } = chatsSideFiltersSelector(state)(chatSide);

    if (!channels?.length) return true;

    const providers = channels.map(({ value }) => value).flat();
    return providers.includes(chat.provider);
  };

export const isValidChatToBotAccountsFilterSelector =
  (state: RootState) =>
  (chat: ChatTray, chatSide: ChatSideType = ChatSideType.ON_HOLD) => {
    const { botAccounts = [] } = chatsSideFiltersSelector(state)(chatSide);

    if (!botAccounts?.length) return true;

    const botAccountsIds = botAccounts.map(({ id }) => id);
    return botAccountsIds.includes(chat?.viaBotAccountId);
  };

export const isValidChatToDepartmentsNodesFilterSelector =
  (state: RootState) =>
  (chat: ChatTray, chatSide: ChatSideType = ChatSideType.ON_HOLD) => {
    const { departmentNodes = [] } = chatsSideFiltersSelector(state)(chatSide);

    if (!departmentNodes?.length) return true;

    const departmentsNodesIds = departmentNodes.map(({ bot }) => bot.id);
    // TODO check later merge with refactor timeline but don't exists businessProcessNode.bot.id
    return (
      chat?.businessProcessNode?.bot?.id &&
      departmentsNodesIds.includes(chat.businessProcessNode.bot.id)
    );
  };

export const isValidChatToTagsFilterSelector =
  (state: RootState) =>
  (chat: ChatTray, chatSide: ChatSideType = ChatSideType.ON_HOLD) => {
    const { tags = [] } = chatsSideFiltersSelector(state)(chatSide);

    if (!tags?.length) return true;

    const tagsNames = tags.map(({ tagName }) => ({ tagName }));

    // eslint-disable-next-line no-restricted-syntax
    for (const tagName of chat?.tags) {
      if (tagsNames.includes(tagName)) return true;
    }

    return false;
  };

export const isValidChatToContactTagsFilterSelector =
  (state: RootState) =>
  (chat: ChatTray, chatSide: ChatSideType = ChatSideType.ON_HOLD) => {
    const { contactTags = [] } = chatsSideFiltersSelector(state)(chatSide);
    if (!contactTags?.length) return true;

    // eslint-disable-next-line no-restricted-syntax
    for (const tagId of chat?.contact?.tags || []) {
      if (contactTags.some(({ tagId: cTagId = -1 }) => cTagId === tagId))
        return true;
    }

    return false;
  };
