import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import {
  ChatContactAttrs,
  ChatDetails,
  ChatFullContact,
  ChatHistoryDirection,
  ChatReferences,
} from '@src/types/chatViewert';
import type { DepartmentList, ErrorData, UUID } from '@types';
import { ChatHistoryItem } from './chatHistory';

export type ChatViewerState = {
  chatDetails: {
    loading: boolean;
    error?: ErrorData | null;
  } & Partial<ChatDetails>;

  chatRefs: {
    error?: ErrorData | null;
    direction: {
      [side: ChatHistoryDirection | string]: {
        offset: number;
        limit: number;
        completed: boolean;
        loading: boolean;
        totalChats: number;
      };
    };
  } & ChatReferences;

  contactInChat: {
    loading: boolean;
    error?: ErrorData | null;
  } & Partial<ChatFullContact>;

  contactAttrs: {
    attrs: ChatContactAttrs[];
    loading: boolean;
    error?: ErrorData | null;
  };

  chatMsgsHistory: {
    referenceChatId: string;
    firstItemIndex: number;
    error?: ErrorData | null;
    chats: ChatHistoryItem[];
    tempChats: ChatHistoryItem[];
    direction: {
      [side: ChatHistoryDirection | string]: {
        offset: number;
        limit: number;
        completed: boolean;
        loading: boolean;
      };
    };
  };
  showDepartmentList: boolean;
  departments?: WppDepartments | null;

  takeChatFromAudit: {
    loading: boolean;
    error: ErrorData | null;
    ready: boolean;
  };
};

type WppDepartments = {
  list?: DepartmentList[] | null;
  title?: string | null;
};

export const chatHistoryInitialState: ChatViewerState['chatMsgsHistory']['direction'][string] =
  {
    loading: false,
    offset: 0,
    limit: 30,
    completed: false,
  };

export const chatRefsInitialState: ChatViewerState['chatRefs']['direction'][string] =
  {
    ...chatHistoryInitialState,
    totalChats: 0,
    limit: 10,
  };

type ChatViewerReducer<P = void> = (
  state: ChatViewerState,
  action: PayloadAction<P>
) => void;

const initialState: ChatViewerState = {
  chatDetails: {
    loading: false,
    error: null,
  },
  chatRefs: {
    chats: [],
    direction: {
      [ChatHistoryDirection.TOP]: chatRefsInitialState,
      [ChatHistoryDirection.BOTTOM]: chatRefsInitialState,
    },
  },
  contactInChat: {
    loading: false,
    error: null,
  },
  contactAttrs: {
    loading: false,
    error: null,
    attrs: [],
  },
  chatMsgsHistory: {
    chats: [],
    tempChats: [],
    error: null,
    firstItemIndex: 10_000_001,
    referenceChatId: '',
    direction: {
      [ChatHistoryDirection.TOP]: chatHistoryInitialState,
      [ChatHistoryDirection.BOTTOM]: chatHistoryInitialState,
    },
  },
  showDepartmentList: false,
  departments: { list: [], title: '' },

  takeChatFromAudit: {
    loading: false,
    error: null,
    ready: false,
  },
};

const fetchChatDetails: ChatViewerReducer<{
  chatId: string;
  merchantToken: string;
}> = state => {
  state.chatDetails = { loading: true, error: null };
};

const fetchChatDetailsFailure: ChatViewerReducer<ErrorData> = (
  state,
  { payload }
) => {
  state.chatDetails = { loading: false, error: payload };
};

const fetchChatDetailsSuccess: ChatViewerReducer<ChatDetails> = (
  state,
  { payload: chatDetails }
) => {
  state.chatDetails = { ...chatDetails, loading: false, error: null };
};

const fetchChatReferences: ChatViewerReducer<{
  chatId: string;
  merchantToken: string;
  direction: ChatHistoryDirection;
  resetAndReload?: boolean;
}> = (state, { payload }) => {
  if (payload?.resetAndReload) state.chatRefs = initialState.chatRefs;
  else state.chatRefs.error = null;
};

const isLoadingFetchChatReferences: ChatViewerReducer<ChatHistoryDirection> = (
  state,
  { payload: directionSide }
) => {
  state.chatRefs.direction[directionSide].loading = true;
};

const fetchChatRefsFailure: ChatViewerReducer<{
  error: ErrorData;
  direction: ChatHistoryDirection;
}> = (state, { payload }) => {
  state.chatRefs.error = payload.error;
  state.chatRefs.direction[payload.direction].loading = false;
};

const fetchChatRefsSuccess: ChatViewerReducer<
  {
    direction: ChatHistoryDirection;
    referenceChatId: string;
    totalChats: number;
  } & ChatReferences
> = (state, { payload }) => {
  const qtyChats = payload.chats.length;

  state.chatRefs.direction[payload.direction].loading = false;
  state.chatRefs.direction[payload.direction].offset += qtyChats;
  state.chatRefs.direction[payload.direction].totalChats = qtyChats;
  state.chatRefs.error = null;

  if (payload.direction === ChatHistoryDirection.TOP) {
    state.chatRefs.chats.unshift(
      ...payload.chats.filter(({ id }) => payload.referenceChatId !== id)
    );
  } else if (payload.direction === ChatHistoryDirection.BOTTOM) {
    state.chatRefs.chats?.push(...payload.chats);
  }
  if (
    qtyChats === 0 ||
    qtyChats < state.chatRefs.direction[payload.direction].limit
  )
    state.chatRefs.direction[payload.direction].completed = true;
};

const fetchContactInChat: ChatViewerReducer<{
  contactId: number;
  merchantToken: string;
}> = state => {
  state.contactInChat = { loading: true, error: null };
};

const fetchContactInChatFailure: ChatViewerReducer<ErrorData> = (
  state,
  { payload }
) => {
  state.contactInChat = { error: payload, loading: false };
};

const fetchContactInChatSuccess: ChatViewerReducer<ChatFullContact> = (
  state,
  { payload: contact }
) => {
  state.contactInChat = { ...contact, error: null, loading: false };
};

const fetchContactAttrsInChat: ChatViewerReducer<{
  botId: string;
  merchantToken: string;
}> = state => {
  state.contactAttrs = { loading: true, error: null, attrs: [] };
};

const fetchContactAttrsInChatFailure: ChatViewerReducer<ErrorData> = (
  state,
  { payload }
) => {
  state.contactAttrs = { error: payload, loading: false, attrs: [] };
};

const fetchContactAttrsInChatSuccess: ChatViewerReducer<ChatContactAttrs[]> = (
  state,
  { payload: attrs }
) => {
  state.contactAttrs = { attrs, error: null, loading: false };
};

export type FetchChatMsgsHistoryPayload = {
  chatId: string;
  merchantToken: string;
  direction: ChatHistoryDirection;
  resetAndReload?: boolean;
};

const fetchChatMsgsHistory: ChatViewerReducer<FetchChatMsgsHistoryPayload> = (
  state,
  { payload }
) => {
  if (payload?.resetAndReload) {
    state.chatMsgsHistory = {
      ...initialState.chatMsgsHistory,
      referenceChatId: payload.chatId,
    };
  } else state.chatMsgsHistory.error = null;
};

const fetchChatMsgsHistoryFailure: ChatViewerReducer<{
  error: ErrorData;
  direction: ChatHistoryDirection;
}> = (state, { payload }) => {
  state.chatMsgsHistory.direction[payload.direction].loading = false;
  state.chatMsgsHistory.error = payload.error;
};

const fetchChatMsgsHistorySuccess: ChatViewerReducer<{
  chats: ChatViewerState['chatMsgsHistory']['chats'];
  direction: ChatHistoryDirection;
  firstItemIndex: number;
  resetAndReload?: boolean;
}> = (state, { payload }) => {
  const { chats, firstItemIndex, direction, resetAndReload } = payload;
  const { offset, limit } = state.chatMsgsHistory.direction[direction];

  state.chatMsgsHistory = {
    ...state.chatMsgsHistory,
    firstItemIndex,
    direction: {
      ...state.chatMsgsHistory.direction,
      [direction]: {
        ...state.chatMsgsHistory.direction[direction],
        offset: offset + limit,
        completed: !chats.length,
        loading: false,
      },
    },
  };

  if (resetAndReload) {
    state.chatMsgsHistory.tempChats = chats;
  } else {
    if (direction === ChatHistoryDirection.TOP) {
      chats.unshift(...state.chatMsgsHistory.tempChats.reverse());
      state.chatMsgsHistory.chats.push(...chats);
    } else {
      state.chatMsgsHistory.chats.unshift(...chats.reverse());
    }
    state.chatMsgsHistory.tempChats = [];
  }
};

const isLoadingFetchChatMsgsHistory: ChatViewerReducer<ChatHistoryDirection> = (
  state,
  { payload: directionSide }
) => {
  state.chatMsgsHistory.direction[directionSide].loading = true;
};

const initChatMsgsHistoryTopOffset: ChatViewerReducer<number> = (
  state,
  { payload: newOffset }
) => {
  state.chatMsgsHistory.direction[ChatHistoryDirection.TOP].offset = newOffset;
};

const onShowDepartmentList: ChatViewerReducer<WppDepartments | undefined> = (
  state,
  action
) => {
  const show = state.showDepartmentList;
  state.showDepartmentList = !show;
  state.departments = !show ? action.payload : null;
};

const takeChatFromAudit: ChatViewerReducer<{ chatId: UUID }> = state => {
  state.takeChatFromAudit.error = null;
  state.takeChatFromAudit.loading = true;
  state.takeChatFromAudit.ready = false;
};

const takeChatFailure: ChatViewerReducer<ErrorData> = (state, action) => {
  state.takeChatFromAudit.loading = false;
  state.takeChatFromAudit.error = action.payload;
};

const takeChatSuccess: ChatViewerReducer = state => {
  state.takeChatFromAudit.loading = false;
};

const clearTakeChatError: ChatViewerReducer = state => {
  state.takeChatFromAudit.error = null;
};

const clearTakeChatReady: ChatViewerReducer = state => {
  state.takeChatFromAudit.ready = false;
};

const handleTakeChatWebSocketEvent: ChatViewerReducer = state => {
  state.takeChatFromAudit.ready = true;
};

const chatViewerSlice = createSlice({
  name: 'chatViewerSlice',
  initialState,
  reducers: {
    fetchChatDetails,
    fetchChatDetailsFailure,
    fetchChatDetailsSuccess,

    fetchChatReferences,
    isLoadingFetchChatReferences,
    fetchChatRefsSuccess,
    fetchChatRefsFailure,

    fetchContactInChat,
    fetchContactInChatFailure,
    fetchContactInChatSuccess,

    fetchContactAttrsInChat,
    fetchContactAttrsInChatFailure,
    fetchContactAttrsInChatSuccess,

    fetchChatMsgsHistory,
    isLoadingFetchChatMsgsHistory,
    fetchChatMsgsHistoryFailure,
    fetchChatMsgsHistorySuccess,
    initChatMsgsHistoryTopOffset,
    onShowDepartmentList,

    takeChatFromAudit,
    takeChatFailure,
    takeChatSuccess,
    clearTakeChatError,
    handleTakeChatWebSocketEvent,
    clearTakeChatReady,
  },
});

export default chatViewerSlice.reducer;

export const chatViewerActions = chatViewerSlice.actions;
