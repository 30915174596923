/* eslint-disable no-shadow */
// eslint-disable-next-line import/no-cycle
import { Agent, WhatsAppTemplateState } from '@src/types';
import { ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form';

export enum ButtonType {
  URL = 'URL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  QUICK_REPLY = 'QUICK_REPLY',
}

export enum RedirectionType {
  AGENT = 'AGENT',
  DEPARTMENT = 'DEPARTMENT',
  NONE = 'NONE',
}

export enum TemplateType {
  HEADER = 'HEADER',
  FOOTER = 'FOOTER',
  BUTTONS = 'BUTTONS',
}

export enum FormatType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
}

export enum WhatsAppTemplateType {
  START_RETAKE = 'START_RETAKE',
  BROADCAST = 'BROADCAST',
}

export enum QuickReplyMessageType {
  TEXT = 'TEXT',
  AUDIO = 'AUDIO',
  PDF = 'PDF',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
}

export enum UriType {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
}

export enum WhatsAppTemplateMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export type ButtonComponent = Partial<
  UrlButton & PhoneButton & QuickReplyButton
>;

export type WhatsappTemplateComponent = {
  type: TemplateType;
  format?: FormatType;
  image?: File;
  document?: File;
  video?: File;
  url?: string;
  mime?: string;
  text?: string;
  buttons?: ButtonComponent[];
};

export type UrlButton = {
  text: string;
  type: ButtonType;
  uriType: UriType;
  url: string;
  id?: string;
};

export type PhoneButton = {
  text: string;
  type: ButtonType;
  phoneNumber: string;
};

export type QuickReplyButton = {
  text: string;
  type: ButtonType;
  quickReplyButtonSettings: QuickReplyButtonSettings;
};

export type QuickReplyMessage = {
  type: QuickReplyMessageType;
  text?: string;
  url?: string;
  file?: File | null | string;
};

export type QuickReplyButtonSettings = {
  redirection: {
    target: RedirectionType;
    targetId: number | null;
  };
  action: {
    type?: 'SEND_MESSAGE' | 'NONE';
    messages: QuickReplyMessage[];
  };
};

export type WhatsAppTemplateFormValues = {
  mode: WhatsAppTemplateMode;
  name: string;
  messageWithPlaceHolders: string;
  type: string;
  status: WhatsAppTemplateState;
  language: string;
  category: string;
  components: WhatsappTemplateComponent[];
  headerComponent: boolean;
  footerComponent: boolean;
  buttonsComponent: boolean;
  formError?: string;
};

export type WppTemplateEditorButton = {
  onDeleteDraggableButton: (fieldIndex: number) => void;
  editMode: boolean;
  form: UseFormReturn<WhatsAppTemplateFormValues>;
  agents: Agent[];
  buttonIndex: number;
};

export type WhatsAppButtonsType = {
  onAddItem: (btnType: ButtonType, fields?: ButtonComponent[]) => void;
  buttonIndex: number;
  form: UseFormReturn<WhatsAppTemplateFormValues>;
  isBSP: boolean;
};

export type SectionProps = {
  children: ReactNode;
  title: string;
  onChangePosition: () => void;
  disableButton: boolean;
};

export type DraggableWhatsAppButtonType = {
  buttonIndex: number;
  fieldIndex: number;
  buttonType: ButtonType;
  agents: Agent[] | [];
  draggableIndex: number;
  onDeleteDraggableButton: (fieldIndex: number) => void;
  form: UseFormReturn<WhatsAppTemplateFormValues>;
};

export type QuickReplyType = {
  buttonIndex: number;
  fieldIndex: number;
  form: UseFormReturn<WhatsAppTemplateFormValues>;
  editMode: boolean;
  templateType: string;
  agents: Agent[] | [];
  onDeleteDraggableButton: (fieldIndex: number) => void;
};

export type MessageListType = {
  buttonIndex: number;
  fieldIndex: number;
  messages: QuickReplyMessage[];
  form: UseFormReturn<WhatsAppTemplateFormValues>;
  editMode: boolean;
};
