import { RootState } from '@types';

export const preferencesSelect = (state: RootState) =>
  state.generalProperties?.preferences;

export const consolePreferencesSelect = (state: RootState) =>
  state.generalProperties?.preferences?.agentConsoleConfig;

export const showChatWithoutDeptToAllAgentsPreferencesSelect = (
  state: RootState
) => {
  const showChatWithoutDeptToAllAgentss =
    state.generalProperties?.preferences?.agentConsoleConfig
      ?.showChatWithoutDeptToAllAgents;

  return showChatWithoutDeptToAllAgentss ?? true;
};
