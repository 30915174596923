/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable max-classes-per-file */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseFile } from '@src/reducers/uploadFiles';
import mediaUtils from '@src/utils/mediaUtils';
import { GoogleMapLocation, isErrorPayload, RootState } from '@types';
import { v4 as uuid } from 'uuid';
import { MediaType, MessagingProvider } from '../model/frontendmodel';

export class UploadError extends Error {
  constructor(public file: File) {
    super('UploadError');
  }
}
export class FileDuplicated extends Error {
  constructor(public file: File) {
    super('FileDuplicated');
  }
}

export class FileSizeExceeded extends Error {
  constructor(public file: File, public maxSizeMB?: number) {
    super('FileSizeExceeded');
  }
}

export class MimeTypeUnsupported extends Error {
  constructor(public file: File) {
    super('MimeTypeUnsupported');
  }
}

export type FileErrorType =
  | UploadError
  | MimeTypeUnsupported
  | FileSizeExceeded
  | FileDuplicated;

export function sameFile(fileL: File, fileR: File) {
  return (
    fileL.name === fileR.name &&
    fileL.size === fileR.size &&
    fileL.type === fileR.type
  );
}

export function checkFile(file: File, msgProvider: MessagingProvider) {
  const mediaType = mediaUtils.mimeTypeToMediaType(file.type);

  if (!mediaUtils.isMediaTypeSupported(mediaType, msgProvider))
    throw new MimeTypeUnsupported(file);

  if (!mediaUtils.checkMaxMediaSize(mediaType, msgProvider, file.size))
    throw new FileSizeExceeded(
      file,
      mediaUtils.getMaxMediaSizeMb(mediaType, msgProvider)
    );

  if (
    mediaType === MediaType.IMAGE &&
    !mediaUtils.checkMimeImageSupport(file.type, msgProvider)
  )
    throw new MimeTypeUnsupported(file);
}

const FileMediaType = [
  MediaType.IMAGE,
  MediaType.AUDIO,
  MediaType.VIDEO,
  MediaType.FILE,
  MediaType.STICKER,
];

export function isFileMediaMessage(msg: ChatMessage): msg is ChatMessageFile {
  return FileMediaType.includes(msg.type);
}
export type ChatMessageFile = {
  type: MediaType.IMAGE | MediaType.AUDIO | MediaType.VIDEO | MediaType.FILE;
  file: File;
  url: string;
  caption?: string;
};

export type ChatMessage =
  | { type: MediaType.TEXT; text: string; quotedMessageId?: string }
  | (ChatMessageFile & { quotedMessageId?: string })
  | ({
      type: MediaType.LOCATION;
      quotedMessageId?: string;
    } & GoogleMapLocation)
  | ({
      type: MediaType.PRODUCT_OVERVIEW;
    } & SendShopifyProduct);

export type MessageState<M = ChatMessage> = {
  id: string;
  chatId: string;
  message: M;
  loading: ChatMessageQueue.Loading[];
  error?: FileErrorType;
  queue: 'standard' | 'attachments';
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ChatMessageQueue {
  export type Loading =
    | 'waiting'
    | 'uploading-file'
    | 'upload-file-success'
    | 'upload-file-failure'
    | 'upload-file-fulfill'
    | 'cancelled'
    | 'sent';
}

export type ChatMessageQueue = {
  standard: {
    [chatId: string]: MessageState[];
  };
  attachments: {
    [chatId: string]: MessageState<ChatMessageFile>[];
  };
};

function prepareMessage<Q extends MessageState['queue']>(
  message: ChatMessage,
  queue: Q,
  chatId: string,
  msgProvider: MessagingProvider
) {
  let error: FileErrorType | undefined;
  let loading: ChatMessageQueue.Loading[] = ['waiting'];

  if (isFileMediaMessage(message)) {
    // fix mediaType
    message.type = mediaUtils.mimeTypeToMediaType(message.file.type);
    message.url = URL.createObjectURL(message.file);

    try {
      checkFile(message.file, msgProvider);
      loading = ['waiting', 'uploading-file'];
    } catch (e) {
      error = e as FileErrorType;
      loading = ['upload-file-failure', 'cancelled'];
    }
  }

  return {
    payload: {
      id: uuid(),
      chatId,
      message,
      loading,
      error,
      queue,
    } as MessageState<Q extends 'standard' ? ChatMessage : ChatMessageFile>,
    error,
  };
}

export type MessageStateAction = ReturnType<typeof prepareMessage>;

function findMessageState(
  state: ChatMessageQueue,
  id: string
): MessageState | undefined {
  return [Object.values(state.standard), Object.values(state.attachments)]
    .flat(2)
    .find(m => m?.id === id);
}

const initialState: ChatMessageQueue = {
  standard: {},
  attachments: {},
};

const chatMessageQueueSlice = createSlice({
  initialState,
  name: 'chatMessageQueue',
  reducers: {
    queueChatMessage: {
      prepare(
        message: ChatMessage,
        chatId: string,
        msgProvider: MessagingProvider
      ) {
        return prepareMessage(message, 'standard', chatId, msgProvider);
      },
      reducer(
        state,
        action: PayloadAction<
          MessageState,
          string,
          never,
          FileErrorType | undefined
        >
      ) {
        const { chatId } = action.payload;

        if (!state.standard[chatId]) state.standard[chatId] = [];

        state.standard[chatId].push(action.payload);
      },
    },
    chatMessageSent(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;

      const messageState = findMessageState(state, id);

      if (messageState) messageState.loading = ['sent'];
    },
    cancelChatMessage(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;

      const messageState = findMessageState(state, id);

      if (!messageState) return;

      messageState.loading = ['cancelled'];
      URL.revokeObjectURL((messageState.message as ChatMessageFile).url || '');
    },
    clearMessageQueue(state, action: PayloadAction<{ chatId: string }>) {
      const { chatId } = action.payload;

      state.standard[chatId] = state.standard[chatId]?.filter(m =>
        m.loading.includes('waiting')
      );

      if (state.standard[chatId]?.length === 0) delete state.standard[chatId];

      state.attachments[chatId] = state.attachments[chatId]?.filter(m =>
        m.loading.includes('waiting')
      );

      if (state.attachments[chatId]?.length === 0)
        delete state.attachments[chatId];
    },
    chatMessageCaption(
      state,
      action: PayloadAction<{ id: string; caption: string }>
    ) {
      const { id, caption } = action.payload;

      const messageState = findMessageState(state, id);
      if (messageState) messageState.message.caption = caption;
    },
    attachFile: {
      prepare(
        message: ChatMessageFile,
        chatId: string,
        msgProvider: MessagingProvider
      ) {
        return prepareMessage(message, 'attachments', chatId, msgProvider);
      },
      reducer(
        state,
        action: PayloadAction<
          MessageState<ChatMessageFile>,
          string,
          never,
          FileErrorType | undefined
        >
      ) {
        const {
          chatId,
          message: { file },
        } = action.payload;

        if (!state.attachments[chatId]) state.attachments[chatId] = [];

        if (
          !state.attachments[chatId]
            .filter(m => m.loading.includes('waiting'))
            .find(m => sameFile(m.message.file, file))
        ) {
          state.attachments[chatId].push(action.payload);
        }
      },
    },
    attachFileSuccess(
      state,
      action: PayloadAction<{
        data: ResponseFile;
        id: string;
      }>
    ) {
      const { id, data } = action.payload;

      const messageState = findMessageState(state, id);

      if (!messageState) return;
      if (messageState.loading.includes('cancelled')) return;

      messageState.loading = ['waiting', 'upload-file-success'];
      (messageState.message as ChatMessageFile).url = data.url;
    },
    attachFileFailure(
      state,
      action: PayloadAction<{ id: string; error: unknown }>
    ) {
      const { id, error } = action.payload;

      const messageState = findMessageState(state, id);

      if (!messageState) return;
      if (messageState.loading.includes('cancelled')) return;

      try {
        const { file } = messageState.message as ChatMessageFile;

        if (isErrorPayload<'INVALID_VALUE' | 'MAX_FILE_SIZE_EXCEEDED'>(error)) {
          if (
            error.errorCode === 'INVALID_VALUE' &&
            error.fieldName === 'mimeType'
          ) {
            throw new MimeTypeUnsupported(file);
          }

          if (error.errorCode === 'MAX_FILE_SIZE_EXCEEDED') {
            throw new FileSizeExceeded(file, -1);
          }
        }

        throw new UploadError(file);
      } catch (err: unknown) {
        messageState.error = err as FileErrorType;
      } finally {
        messageState.loading = ['upload-file-failure'];
      }
    },
    attachFileFulfill(
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) {
      const { id } = action.payload;

      const messageState = findMessageState(state, id);

      if (!messageState) return;

      if (messageState.loading.includes('cancelled')) return;

      messageState.loading.push('upload-file-fulfill');
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    sendAttachmentQueue(_state, _action: PayloadAction<{ chatId: string }>) {},
  },
});

export default chatMessageQueueSlice.reducer;

export const {
  queueChatMessage,
  chatMessageSent,
  cancelChatMessage,
  clearMessageQueue,
  attachFile,
  replaceAttachFile,
  attachFileSuccess,
  attachFileFailure,
  attachFileFulfill,
  sendAttachmentQueue,
  chatMessageCaption,
} = chatMessageQueueSlice.actions;

export const selectNextMessageStateToSent =
  (chatId: string, queue: MessageState['queue']) => (state: RootState) =>
    state.chatMessageQueue[queue][chatId]?.find(m =>
      m.loading.includes('waiting')
    );

export const selectMessageState = (id: string) => (state: RootState) =>
  findMessageState(state.chatMessageQueue, id);

export const selectAttachmentQueue = (chatId: string) => (state: RootState) =>
  state.chatMessageQueue.attachments[chatId]?.filter(m =>
    m.loading.includes('waiting')
  ) || [];

export const selectStandardQueue = (chatId: string) => (state: RootState) =>
  state.chatMessageQueue.standard[chatId]?.filter(m =>
    m.loading.includes('waiting')
  ) || [];
