import agentChatTakenByAuditorAudio from './audio/agent-chat-taken-by-auditor.wav';
import agentChatTransferApproveAudio from './audio/agent-chat-transfer-approve.mp3';
import agentChatTransferCancelAudio from './audio/agent-chat-transfer-reject.mp3';
import agentChatTransferRequestAudio from './audio/agent-chat-transfer-request.mp3';
import newInMsgAudio from './audio/blop-mark_diangelo.wav';
import logoB2CBotApi from './images/b2capi-icon-v2.png';
import B2ChatBLogo from './images/b2chat-b-blue.png';
import B2ChatBLigthBlueLogo from './images/b2chat-light-blue.png';
import B2ChatLight from './images/b2chat-light.png';
import B2ChatLogoDesktop from './images/b2chat-logo.desktop.png';
import blankAgentAvatar from './images/blank-agent-avatar.png';
import facebookMessengerLogo from './images/channels-setup/fb-messenger-90x90.png';
import instagramLogo from './images/channels-setup/instagram-90x90.png';
import livechatLogo from './images/channels-setup/livechat-90x90.png';
import telegramLogo from './images/channels-setup/telegram-90x90.png';
import telegramBotFather from './images/channels-setup/telegram-bot-father.jpeg';
import telegramStep12 from './images/channels-setup/telegram-step-1-2-s.png';
import telegramStep13 from './images/channels-setup/telegram-step-1-4-s.png';
import telegramStep21 from './images/channels-setup/telegram-step-2-1-s.png';
import whatsappLogo from './images/channels-setup/whatsapp-90x90.png';
import facebookMessengerSmallLogo from './images/fb-messenger-26x26.png';
import freshdeskLogo from './images/freshdesk-logo.png';
import instagramSmallLogo from './images/instagram-26x26.png';
import livechatSmallLogo from './images/livechat-26x26.png';
import loadingGif from './images/loading.gif';
import NoImgAvailable from './images/noimageavailable.png';
import smsLogo from './images/sms-icon.png';
import telegramSmallLogo from './images/telegram-26x26.png';
import terminal from './images/terminal.png';
import whatsappSmallLogo from './images/whatsapp-26x26.png';
import whatsappBusinessSmallLogo from './images/whatsapp-business-26x26.png';
import zendeskLogo from './images/zendesk-logo.png';

const agentChatTransferRejectAudio = agentChatTransferApproveAudio;

export {
  B2ChatBLigthBlueLogo,
  B2ChatBLogo,
  B2ChatLight,
  B2ChatLogoDesktop,
  NoImgAvailable,
  agentChatTakenByAuditorAudio,
  agentChatTransferApproveAudio,
  agentChatTransferCancelAudio,
  agentChatTransferRejectAudio,
  agentChatTransferRequestAudio,
  blankAgentAvatar,
  facebookMessengerLogo,
  facebookMessengerSmallLogo,
  freshdeskLogo,
  instagramLogo,
  instagramSmallLogo,
  livechatLogo,
  livechatSmallLogo,
  loadingGif,
  logoB2CBotApi,
  newInMsgAudio,
  smsLogo,
  telegramBotFather,
  telegramLogo,
  telegramSmallLogo,
  telegramStep12,
  telegramStep13,
  telegramStep21,
  terminal,
  whatsappBusinessSmallLogo,
  whatsappLogo,
  whatsappSmallLogo,
  zendeskLogo,
};
