import { ChatTray } from './chats';
// eslint-disable-next-line import/no-cycle
import { Agent, TimelineEvent, UUID } from './index';

// eslint-disable-next-line no-shadow
export enum ChatHistoryDirection {
  BOTTOM = 'FORWARD',
  TOP = 'BACKWARD',
}

export type ChatHistory = {
  chats: ChatHistoryMessages[];
};

export type ChatHistoryMessages = {
  id: UUID;
  status?:
    | 'OPENED'
    | 'CLOSED'
    | 'PICKED_UP'
    | 'CLOSED_BY_CONTACT'
    | 'LEFT_BY_CONTACT'
    | 'RESPONDED_BY_AGENT';
  assignedTo?: {
    id: number;
    fullName: string;
    avatarUrl: string;
  };
  events: TimelineEvent[];
  totalEvents: number;
};

export type ChatViewerUrlParams = {
  chatId: string;
  merchantToken: string;
};

export type ChatReferences = {
  chats: ChatReferenceItem[];
};

export type ChatReferenceItem = {
  assignedTo?: {
    id: number;
    fullName: string;
    avatarUrl: string;
  };
} & Omit<ChatTray, 'events'>;

export type ChatDetails = { assignedTo: Agent } & ChatTray;

export type ChatFullContact = {
  id: number;
  fullName: string;
  email: string;
  avatarUrl?: string;
  address: string;
  cityName: string;
  country: {
    isoCode: number;
    name: string;
    languageCode: string;
  };
  province: {
    id: number;
    code: string;
    name: string;
  };
  attributes: {
    attrId: number;
    name: string;
    value: string;
  }[];
  blacklisted: boolean;
  company: string;
  createAt: number;
  habeasdata: boolean;
  identificationNumber: string;
  isNewContact: boolean;
  lastUpdate: number;
  mobileNumber: string;
  phoneNumber: string;
  totalCount: number;
};

export type ChatContactAttrs = {
  id: number;
  name: string;
  type: string;
  activated: boolean;
  required: boolean;
  inPrechat: boolean;
  order: number;
  value?: string | number | boolean;
  // possibleValues: [],
  numeric: boolean;
  list: boolean;
  shortText: boolean;
  largeText: boolean;
};
