/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DataResponse, ErrorData } from '@b2chat/chat-center-sdk';
import B2ChatClient from '@client-sdk';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  ACCEPT_ASSIGNED_CHAT_REQUEST,
  CHAT_CLOSE_OK,
  INCOMING_REACTION,
  MESSAGE_READ_BY_CONTACT,
  OUT_MESSAGE_DELIVERY_CONFIRMED,
  OUT_MESSAGE_DELIVERY_FAILED,
  OUT_MESSAGE_RECEIPT,
  SEND_MESSAGE_OK,
  SET_ACTIVE_CHAT,
  START_SENDING_MESSAGE,
} from '@src/actions/agentconsole';
import { MediaType } from '@src/model/frontendmodel';
import {
  chatHistoryActions,
  ChatHistoryState,
} from '@src/reducers/chatHistory';
import { receivedMessageChat, resumeChatRequested } from '@src/reducers/chats';
import { acceptChatTransfer } from '@src/reducers/chats/chatTransfer';
import { selectChatHistory } from '@src/selectors/chatHistory';
import { activeChatIdSelector } from '@src/selectors/chats';
import { TimelineChat, TimelineEvent } from '@src/types';
import { ChatTrayXfer } from '@src/types/chats';

export type TimelineResponse = {
  chats: TimelineChat[];
};

function* fetchChatHistoryEventsSaga(
  action: ReturnType<typeof chatHistoryActions.fetchEvents>
) {
  const { referenceChatId } = action.payload;
  const state: ChatHistoryState = yield select(selectChatHistory);

  if (!referenceChatId || !state[referenceChatId]) return;

  const { limit, offset } = state[referenceChatId];

  try {
    const controller = new AbortController();

    const result: {
      cancel: PayloadAction;
      response: DataResponse<TimelineResponse>;
    } = yield race({
      cancel: take(chatHistoryActions.cancelFetchEvents),
      response: call(B2ChatClient.resources.chatTimeLine.actions.query, {
        signal: controller.signal,
        data: {
          referenceChatId,
          limit,
          offset,
        },
      }),
    });

    if (result.cancel) {
      controller.abort();
      return;
    }

    const { response } = result;

    if (response.error) {
      throw response.error;
    } else {
      const { chats } = response.data;

      if (chats.length === 0) {
        yield put(chatHistoryActions.completed({ referenceChatId }));
        return;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const chat of chats) {
        // eslint-disable-next-line no-restricted-syntax
        for (const event of chat.events) {
          if (chat.status === 'CLOSED') event.disabledActions = true;
          if (!event?.mediaType) event.mediaType = event.type as MediaType;
        }
      }
      yield put(chatHistoryActions.increaseOffset({ referenceChatId }));
      yield put(
        chatHistoryActions.appendChat({
          referenceChatId,
          chunks: chats,
        })
      );
    }
  } catch (e) {
    yield put(
      chatHistoryActions.fetchEventsFailure({
        referenceChatId,
        error: e as ErrorData,
      })
    );
  } finally {
    yield delay(100); // wait a short time for end timeline rendering
    yield put(chatHistoryActions.fetchEventsFulfill({ referenceChatId }));
  }
}
function* onCloseChat(action: unknown) {
  type ActionType = { chat: { id: string } };
  const referenceChatId = (action as ActionType).chat.id;

  yield put(chatHistoryActions.cancelFetchEvents({ referenceChatId }));
  yield put(chatHistoryActions.removeChatHistory({ referenceChatId }));
}

function* onUnsetChatTransfer({
  payload,
}: PayloadAction<{
  transfer: ChatTrayXfer;
  transferredToChatId: string;
}>) {
  const { transfer, transferredToChatId } = payload;

  yield put(
    chatHistoryActions.cancelFetchEvents({
      referenceChatId: transferredToChatId,
    })
  );

  const isTransferredToMe: boolean = yield select(
    state => state.chats.activeAgentId === transfer?.targetAgent?.id
  );

  if (!isTransferredToMe) {
    yield put(
      chatHistoryActions.removeChatHistory({
        referenceChatId: transferredToChatId,
      })
    );
  } else {
    yield put(
      chatHistoryActions.updateReferenceChatId({
        referenceChatId: transfer.chatId,
        newReferenceChatId: transferredToChatId,
      })
    );
  }
}

export function* processMessage(action: {
  type: string;
  [key: string]: unknown;
}) {
  const isReceivedMessageChatAction = receivedMessageChat.match(action);
  if (
    [START_SENDING_MESSAGE, SEND_MESSAGE_OK].includes(action.type) ||
    isReceivedMessageChatAction
  ) {
    let chatId: string;
    let event: TimelineEvent;
    if (isReceivedMessageChatAction) {
      event = action.payload.message as TimelineEvent;
      chatId = action.payload.chatId;
    } else {
      const { chatId: _chatId, ...message } =
        action.message as TimelineEvent & {
          chatId: string;
        };
      event = message;
      chatId = _chatId;
    }

    event.direction = isReceivedMessageChatAction ? 'INCOMING' : 'OUTGOING';

    if (!event?.mediaType)
      event.mediaType = (event.type as MediaType) || 'TEXT';

    if (
      event?.text?.length &&
      !event.mediaUrl?.indexOf(event.text) &&
      [MediaType.IMAGE, MediaType.VIDEO].includes(event?.mediaType as MediaType)
    ) {
      event.isCaptionDefined = true;
    }

    // TODO temporary map websocket response AD
    // @ts-ignore
    if (event?.adMessage?.ad || event?.ad) {
      // @ts-ignore
      event.adPublicity = event?.adMessage?.ad
        ? // @ts-ignore
          event?.adMessage?.ad
        : // @ts-ignore
          event.ad;
    }

    const assignedTo: TimelineChat['assignedTo'] = yield select(state => ({
      id: state.loginAuthentication.success.id,
      fullName: state.loginAuthentication.success.fullName,
      avatarUrl: state.loginAuthentication.success.avatarUrl,
    }));

    yield put(
      chatHistoryActions.unshiftEvent({
        referenceChatId: chatId,
        chatId,
        event,
        assignedTo,
      })
    );
    return;
  }

  if (
    [OUT_MESSAGE_DELIVERY_CONFIRMED, OUT_MESSAGE_DELIVERY_FAILED].includes(
      action.type
    )
  ) {
    const { type, chatId, messageId } = action as Record<
      'type' | 'chatId' | 'messageId',
      string
    >;

    yield put(
      chatHistoryActions.unshiftEvent({
        referenceChatId: chatId,
        chatId,
        event: {
          messageId,
          deliveryAck: type === OUT_MESSAGE_DELIVERY_CONFIRMED,
          deliveryFailed: type === OUT_MESSAGE_DELIVERY_FAILED,
        },
      })
    );
    return;
  }

  if (action.type === MESSAGE_READ_BY_CONTACT) {
    const { chatId, messageIds } = action as {
      type: string;
      chatId: string;
      messageIds: {
        timestamp: number;
        providerMessageId: string;
        messageId: string;
      };
    };

    yield put(
      chatHistoryActions.unshiftEvent({
        chatId,
        referenceChatId: chatId,
        event: {
          messageId: messageIds.messageId,
          readByContact: true,
        },
      })
    );

    return;
  }

  if (action.type === OUT_MESSAGE_RECEIPT) {
    const { chatId, messageId } = action as Record<
      'type' | 'chatId' | 'messageId',
      string
    >;

    yield put(
      chatHistoryActions.unshiftEvent({
        referenceChatId: chatId,
        chatId,
        event: { messageId, receivedByContact: true },
      })
    );
    return;
  }

  if (action.type === INCOMING_REACTION) {
    const { chatId, messageId, emoji } = action as {
      type: string;
      chatId: string;
      messageId: string;
      emoji: string[];
    };

    yield put(
      chatHistoryActions.unshiftEvent({
        referenceChatId: chatId,
        chatId,
        event: { messageId, emojiReaction: emoji },
      })
    );
    return;
  }

  if (resumeChatRequested.match(action)) {
    const { chatId, lastMsgSentAt } = action.payload as {
      chatId: string;
      lastMsgSentAt: number;
    };

    yield put(
      chatHistoryActions.unshiftEvent({
        referenceChatId: chatId,
        chatId,
        event: {
          type: 'AGENT_REQUESTS_RESUME_CHAT',
          sendingTime: lastMsgSentAt,
          timestamp: lastMsgSentAt,
        },
        status: 'PICKED_UP',
      })
    );
  }
}

function* onActiveChatChanged(action: unknown) {
  const { chatId } = action as { chatId: string };

  yield put(
    chatHistoryActions.activateChatHistory({ referenceChatId: chatId })
  );
}

function* chatHistoryCacheControl() {
  const MIN = 1000 * 60;

  while (true) {
    yield delay(2 * MIN);

    const state: ChatHistoryState = yield select(selectChatHistory);
    const activeChatId: string = yield select(activeChatIdSelector);

    const unusedChats = Object.values(state).filter(
      chat =>
        chat.referenceChatId !== activeChatId &&
        Date.now() - chat.lastSeen > 5 * MIN
    );

    yield put(
      chatHistoryActions.activateChatHistory({ referenceChatId: activeChatId })
    );

    yield all(
      unusedChats.map(({ referenceChatId }) =>
        put(chatHistoryActions.removeChatHistory({ referenceChatId }))
      )
    );
  }
}

export default function* chatHistory() {
  yield takeEvery(
    [
      START_SENDING_MESSAGE,
      SEND_MESSAGE_OK,
      OUT_MESSAGE_DELIVERY_CONFIRMED,
      OUT_MESSAGE_DELIVERY_FAILED,
      OUT_MESSAGE_RECEIPT,
      MESSAGE_READ_BY_CONTACT,
      receivedMessageChat,
      resumeChatRequested,
      INCOMING_REACTION,
    ],
    processMessage
  );
  yield takeEvery(acceptChatTransfer, onUnsetChatTransfer);
  yield takeLatest(CHAT_CLOSE_OK, onCloseChat);
  yield takeLatest(chatHistoryActions.fetchEvents, fetchChatHistoryEventsSaga);
  yield takeLatest(
    [SET_ACTIVE_CHAT, ACCEPT_ASSIGNED_CHAT_REQUEST],
    onActiveChatChanged
  );
  yield fork(chatHistoryCacheControl);
}
